<template>
  <sms-content title="Guitar songs">
    <sms-grid-row>
      <sms-card>
        Sort
        <a @click.prevent="toggleSorting('last_viewed')" href="#">
          Last viewed<template v-show="sorting.type === 'last_viewed'">: {{ sorting.dir }}</template>
        </a>
      </sms-card>

      <sms-card :body-padding="0">

        <div class="list-group">
          <div v-for="song in sortedAndFilteredItems" :key="song.id" href="#" class="list-group-item list-group-item-action flex-column align-items-start active">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                <router-link :to="{name: 'guitar-song', params: {id: song.id}}">
                  {{ song.band_name }} - {{ song.name }}
                </router-link>
              </h5>
              <small><strong>{{ song.tuning }} {{ song.tuningNote }}</strong></small>
            </div>
<!--            <p class="mb-1">123</p>-->
            <small>{{ song.last_viewed | formatUnixTimestamp }}</small>
          </div>
        </div>

      </sms-card>
    </sms-grid-row>

    <template #tools>
      <a @click.prevent="addSong" href="#" class="btn btn-primary btn-icon btn-sm text-white mr-2">
        <span>
            <i class="fe fe-plus"></i>
        </span> Add song
      </a>
    </template>
  </sms-content>
</template>

<script>
import axios from "@/plugins/axios"
import SmsGridRow from "@/views/components/GridRow";
import SmsContent from "@/views/partials/Content";
import SmsCard from "@/views/components/Card";
import _ from 'lodash'

const months = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
]

export default {
  components: {SmsCard, SmsContent, SmsGridRow},
  data() {
    return {
      songs: [],
      sorting: {
        type: 'last_viewed',
        dir: 'desc',
      },
    }
  },
  mounted() {
    axios.get('/guitar/songs').then(response => {
      this.songs = response.data.data
    })
  },
  computed: {
    table() {
      return {
        headings: [
          {
            name: 'artist',
            label: 'Artist',
          },
          {
            name: 'song',
            label: 'Song',
          },
          {
            name: 'tuning',
            label: 'Tuning',
          },
        ]
      }
    },
    sortedAndFilteredItems() {
      return _.orderBy(this.songs, [this.sorting.type], [this.sorting.dir])
    },
  },
  filters: {
    formatUnixTimestamp(time) {
      let date = new Date(time * 1000)

      return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear()
    }
  },
  methods: {
    addSong() {
      axios.post('/guitar/song', {
        name: 'Name',
        band_name: 'Band name',
      }).then(response => {
        this.$router.push({name: 'guitar-song', params: {id: response.data.data.id}})
      })
    },
    toggleSorting(type) {
      this.sorting.type = type
      this.sorting.dir = this.sorting.dir === 'asc' ? 'desc' : 'asc'
    },
  }
}
</script>

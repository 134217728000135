import axios from "@/plugins/axios"
import {Howl, Howler} from "howler"

let interval = null

export default {
  namespaced: true,
  state: {
    song: null,
    sound: null,
    soundId: null,
    soundData: {
      loaded: false,
      duration: null,
      currentPosition: 0,
    },
    currentTrack: null,
    timerId: null,
    currentPlayingSprite: null,
    delay: 0,
  },
  mutations: {
    setTimerId(state, timerId) {
      state.timerId = timerId
    },
    setSoundId(state, soundId) {
      state.soundId = soundId
    },
    setSong(state, song) {
      state.song = song
    },
    setSound(state, sound) {
      state.sound = sound
    },
    setSoundData(state, data) {
      state.soundData = data
    },
    setCurrentTrack(state, data) {
      state.currentTrack = data
    },
    setCurrentPlayingSprite(state, data) {
      state.currentPlayingSprite = data
    },
    setDelay(state, delay) {
      state.delay = delay
    },
  },
  actions: {
    delay({state, commit}, delay) {
      if (interval) {
        clearInterval(interval)
      }

      interval = setInterval(x => {
        let newDelay = state.delay - 1

        if (newDelay >= 0) {
          commit('setDelay', newDelay)
        }
      }, 1000)

      return new Promise(resolve => setTimeout(x => {
        clearInterval(interval)

        commit('setDelay', 0)

        setTimeout(x => {
          commit('setDelay', delay)
        }, 2000)

        resolve()
      }, delay * 1000));
    },
    async playSound({state, commit, dispatch}, spriteName) {
      console.log('action playSound', spriteName)

      await dispatch('delay', state.delay)

      // if play pressed on same sprite just skip it
      if (state.sound.playing()) {
        if (state.currentPlayingSprite === spriteName) {
          return
        }

        // else stop it first
        console.log('sound is playing. Stopping it...')

        dispatch('stopSound')
      }

      let playSoundId = null

      // если играет тот же спрайт
      if (state.currentPlayingSprite === spriteName) {
        playSoundId = state.soundId
      } else {
        commit('setCurrentPlayingSprite', spriteName)

        playSoundId = spriteName
      }

      const soundId = state.sound.play(playSoundId)

      // Set speed rate
      // по-умолчанию 0.5
      let speedRate = 1
      if (spriteName === 'full') {
        // для всего трека
        if (state.song.speed) {
          speedRate = state.song.speed
        }
      } else {
        if (state.song.segments[spriteName].speed) {
          speedRate = state.song.segments[spriteName].speed
        }
      }

      state.sound.rate(speedRate, soundId)

      // ------------------------------

      commit('setSoundId', soundId)

      if (state.timerId) {
        clearInterval(state.timerId)
        commit('setTimerId', null)
      }

      const intervalId = setInterval(() => {
        state.soundData.currentPosition = state.sound.seek(soundId)
      }, 50)

      commit('setTimerId', intervalId)
    },
    speedRateSound({state}, speedRate) {
      if (state.sound.playing()) {
        state.sound.rate(speedRate, state.soundId)
      }
    },
    seekSound({state}, position) {
      state.sound.seek(position, state.soundId)
    },
    stopSound({state, commit}) {
      state.sound.stop(state.soundId)

      clearInterval(state.timerId)
      commit('setTimerId', null)

      state.soundData.currentPosition = 0

      commit('setSoundData', state.soundData)
    },
    pauseSound({state, commit}) {
      state.sound.pause(state.soundId)

      clearInterval(state.timerId)
      commit('setTimerId', null)
    },
    loadTrack({state, commit, dispatch}, trackId) {
      console.log('loading track...')

      commit('setCurrentTrack', state.song.tracks[trackId])

      dispatch('loadSound', state.currentTrack)
    },
    reloadSound({dispatch, state}) {
        dispatch('loadSound', state.currentTrack)
    },
    loadSound({commit, state}, track) {
      console.log('load sounding...', track)

      if (state.sound) {
        console.log('sound unloading...')
        Howler.stop()
      }

      commit('setCurrentPlayingSprite', null)

      // generating sprites
      let sprites = {
        'full': [0, track.duration ? parseFloat(track.duration) * 1000 : 400000, true],
      }

      for(let key in state.song.segments) {
        if (!state.song.segments[key].tracks[track.id]) {
          break
        }

        let start = state.song.segments[key].tracks[track.id].start
        let duration = state.song.segments[key].tracks[track.id].end - state.song.segments[key].tracks[track.id].start
        sprites[key] = [start * 1000, duration * 1000, true]
      }

      console.log('sprites', sprites)

      commit('setSound', new Howl({
        src: [process.env.VUE_APP_MP3_CDN_PATH + '/' + track.mp3_file_name],
        html5: true,
        loop: true,
        sprite: sprites
      }))

      state.sound.on('load', x => {
        let data = state.soundData

        data.loaded = true
        data.duration = parseFloat(state.sound.duration().toFixed(2))
        data.currentPosition = 0

        commit('setSoundData', data)

        console.log('SOUND LOADED -----------------------')
      })
    },
    loadSong({commit, state}, data) {
      if (state.song && state.song.id === data.song_id) {
        return
      }

      commit('setSong', null)

      return new Promise((resolve, reject) => {
        axios.get('/guitar/song/' + data.song_id).then(response => {
          commit('setSong', response.data.data)
          resolve(response.data.data)
        })
      })
    },
    updateSong({commit, state}, data) {
      return new Promise((resolve, reject) => {
          axios.post('/guitar/song/' + state.song.id, data).then(response => {
            commit('setSong', response.data.data)
            resolve(response.data.data)
          })
      })
    },
    addTrackToSong({commit, state}, data) {
      return new Promise((resolve, reject) => {
        axios.post('/guitar/song/' + state.song.id + '/add-track', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }).then(response => {
          commit('setSong', response.data.data)
          resolve(response.data.data)
        })
      })
    },
    deleteTrack({commit, state}, data) {
      return new Promise((resolve, reject) => {
        axios.delete('/guitar/song/' + state.song.id + '/track/' + data.track_id).then(response => {
          commit('setSong', response.data.data)
          resolve(response.data.data)
        })
      })
    },
    addTrackSegment({state, commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('/guitar/song/' + state.song.id + '/track/' + state.currentTrack.id + '/segment', data).then(response => {
          commit('setSong', response.data.data)
          resolve(response.data.data)
        })
      })
    },
    setTrackSegmentPosition({state, commit}, data) {
      return new Promise((resolve, reject) => {
        if (!data.value) {
          data.value = state.soundData.currentPosition.toFixed(2)
        }

        axios.put('/guitar/song/' + state.song.id + '/track/' + state.currentTrack.id + '/segment/' + data.segmentId, data).then(response => {
          commit('setSong', response.data.data)
          resolve(response.data.data)
        })
      })
    },
    setSegmentSpeed({state, commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put('/guitar/song/' + state.song.id + '/segment/' + data.segmentId, {
          speed: data.speed,
        }).then(response => {
          commit('setSong', response.data.data)
          resolve(response.data.data)
        })
      })
    },
    setSegmentLearn({state, commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put('/guitar/song/' + state.song.id + '/segment/' + data.segmentId + '/learn', {
          state: data.isLearn,
        }).then(response => {
          commit('setSong', response.data.data)
          resolve(response.data.data)
        })
      })
    },
  },
  getters: {
    currentTrackId(state) {
      return state.currentTrack.id
    }
  }
}

<template>
  <div style="display: flex" class="block-todo-item" :ref="'todo-item-' + id">
    <input type="checkbox" v-model="isDone" style="margin-right: 10px;" @change="onChange" />
    <content-editable-input
            style="display: inline-block; width: 100%;"
            v-model="content"
            @onEnter="onEnter"
            @onCtrlEnter="onCtrlEnter"
            @onArrowUp="onArrowUp"
            @onArrowDown="onArrowDown"
            @onBlur="onBlur"
            @onEmptyAndBackspace="onEmptyAndBackspace"
    />
  </div>
</template>

<script>
import ContentEditableInput from "@/components/ContentEditableInput"

export default {
  props: ['data', 'editor', 'id', 'index'],
  components: {ContentEditableInput},
  data() {
    return {
      isDone: this.data.is_done,
      content: this.data.text,
    }
  },
  watch: {
    content(val) {
      this.editor.changeBlock(val, this.index, this.id)
    },
  },
  methods: {
    focus() {
      this.$refs['todo-item-' + this.id].children[1].focus()
    },
    getBlockType() {
      return 'todo-item'
    },
    getBlockData() {
      return {
        is_done: this.isDone,
        text: this.content,
      }
    },
    onEnter(text) {
      this.editor.addBlock({
        type: 'todo-item',
        data: {
          is_done: false,
          text: '',
        },
      }, this.index)
    },
    onCtrlEnter(text) {
      this.isDone = !this.isDone

      this.editor.emitOnChange()
    },
    onArrowUp() {
      this.editor.focusPrev(this.index)
    },
    onArrowDown() {
      this.editor.focusNext(this.index)
    },
    onBlur() {
      this.editor.emitOnChange()
    },
    onChange() {
      this.editor.emitOnChange()
    },
    onEmptyAndBackspace(text) {
      this.editor.deleteBlock(this.index, this.id)
    },
  },
}
</script>

<style scoped>
.block-todo-item {
  margin-bottom: 10px;
  padding-left: 5px;
}
.block-todo-item input {
  margin-top: 5px;
}
</style>

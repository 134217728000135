<template>
  <i :class="[
    iconClass,
    color ? 'text-' + color : 'cyan',
  ]"></i>
</template>

<script>
const iconsMap = {
  task: 'fa fa-tasks',
  repeat: 'fa fa-repeat',
}

export default {
  name: 'sms-icon',
  props: {
    name: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  components: {},
  data() {
    return {

    }
  },
  computed: {
    iconClass() {
      return iconsMap[this.name] ? iconsMap[this.name] : this.name
    },
  },
  mounted() {},
  destroyed() {},
  methods: {},
}
</script>

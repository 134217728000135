<template>
  <content-editable-input
          :ref="'paragraph-' + id"
          class="block-paragraph"
          v-model="content"
          @onEnter="onEnter"
          @onCtrlEnter="onCtrlEnter"
          @onArrowUp="onArrowUp"
          @onArrowDown="onArrowDown"
          @onBlur="onBlur"
          @onEmptyAndBackspace="onEmptyAndBackspace"
          @onEmptyAndDelete="onEmptyAndDelete"
  />
</template>

<script>
import ContentEditableInput from "@/components/ContentEditableInput"

export default {
  props: ['data', 'editor', 'id', 'index'],
  components: {ContentEditableInput},
  data() {
    return {
      content: this.data.text,
    }
  },
  computed: {},
  mounted() {
    console.log(this.editor)
  },
  watch: {
    content(val) {
      this.editor.changeBlock(val, this.index, this.id)
    },
  },
  methods: {
    focus() {
      this.$refs['paragraph-' + this.id].$el.focus()
    },
    getBlockType() {
      return 'paragraph'
    },
    getBlockData() {
      return {
        text: this.content,
      }
    },
    onEnter(text) {
      this.editor.addBlock({
        type: 'paragraph',
        data: {
          text: '',
        },
      }, this.index)
    },
    onCtrlEnter(text) {
      this.editor.emitOnChange()
    },
    onArrowUp() {
      this.editor.focusPrev(this.index)
    },
    onArrowDown() {
      this.editor.focusNext(this.index)
    },
    onBlur() {
      this.editor.emitOnChange()
    },
    onEmptyAndBackspace(text) {
      this.editor.deleteBlock(this.index, this.id)
    },
    onEmptyAndDelete(text) {
      this.editor.deleteBlock(this.index, this.id, 'next')
    },
  },
}
</script>

<style scoped>
.block-paragraph {
  margin-bottom: 10px;
  padding-left: 5px;
}
</style>

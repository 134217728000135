<template>
  <div>
    <hr />
    <div style="margin-bottom: 10px;">
      <a href="#" @click.prevent="editMode = !editMode">{{ editMode ? 'view' : 'edit textarea' }}</a>
    </div>

    <div v-show="text && !editMode">
      <div v-html="markdownText" class="markdown-body"></div>
    </div>

    <editable-text :value="text"
                   :inline="false"
                   @onBlur="updateText"
                   v-show="editMode"
    ></editable-text>
  </div>
</template>

<script>
import EditableText from "@/components/EditableText"
import markdownParser from '@/plugins/markdown-parser'

export default {
  props: ['value'],
  components: {EditableText},
  data() {
    return {
      text: this.value,
      editMode: false,
    }
  },
  computed: {
    markdownText() {
      return markdownParser.render(this.text)
    },
  },
  methods: {
    updateText(text) {
      this.text = text

      this.$emit('onUpdate', text)
    },
  },
}
</script>

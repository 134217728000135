<template>
    <div
         class="content-editable"
         contenteditable="true"
         @keypress.enter="onEnter"
         @blur="onBlur"
         :ref="refName"
    >
      <div v-if="inline">{{ value }}</div>
      <pre v-if="!inline">{{ value }}</pre>
    </div>
</template>

<script>
function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export default {
  props: {
    value: {
      type: String,
    },
    inline: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      refName: 'test' + getRandomArbitrary(0, 1000000000),
      backspaceOnEmptyIncrement: 0,
      listener: null
    }
  },
  methods: {
    focus() {
      this.$refs[this.refName].focus()
    },
    onEnter($event) {
      if (!this.inline) {
        return
      }

      $event.preventDefault()

      $event.target.blur()

      this.$emit('onEnter', $event.target.innerText.replace(/^\n|\n$/g, ''))
    },
    onBlur() {
      let text = this.$refs[this.refName].innerText

      if (this.inline) {
        text = text.replace(/^\n|\n$/g, '')
      }

      this.$emit('onBlur', text)
    },
  },
}
</script>

<style scoped>
  div.inline {
    display: inline-block;
  }
  div {
    line-height: 20px;
  }
  .content-editable {
    background: #373c3f;
    padding: 10px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
</style>

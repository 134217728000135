<template>
  <vc-calendar
      :rows="rows"
      :columns="columns"
      :first-day-of-week="2"
      is-dark
      :from-date="new Date('2021-01-01')"
      :attributes="attributes"
  />
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      default: 1,
    },
    columns: {
      type: Number,
      default: 1,
    },
    attributes: {
      type: Array,
      default: () => {},
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  destroyed() {
  },
  methods: {},
}
</script>

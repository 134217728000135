const items = [
  {
    blockType: 'todo-item',
    pattern: '[]',
    checkBlockType: true,
    data(val) {
      return {
        is_done: false,
        text: val.replace('[]', '').trim(),
      }
    },
  },

  {
    blockType: 'divider',
    pattern: '---',
    checkBlockType: true,
    data(val) {
      return {}
    },
  },

  {
    blockType: 'subpage',
    pattern: '/page',
    checkBlockType: true,
    data(val) {
      return {
        page_id: null,
        name: val.replace('/page', '').trim(),
      }
    },
  },

  {
    blockType: 'paragraph',
    pattern: '/par',
    checkBlockType: true,
    data(val) {
      return {
        text: val.replace('/par', '').trim(),
      }
    },
  },

  {
    blockType: 'heading',
    pattern: '/h1',
    checkBlockType: false,
    data(val) {
      return {
        text: val.replace('/h1', '').trim(),
        type: 1,
      }
    },
  },

  {
    blockType: 'heading',
    pattern: '/h2',
    checkBlockType: false,
    data(val) {
      return {
        text: val.replace('/h2', '').trim(),
        type: 2,
      }
    },
  },

  {
    blockType: 'heading',
    pattern: '/h3',
    checkBlockType: false,
    data(val) {
      return {
        text: val.replace('/h3', '').trim(),
        type: 3,
      }
    },
  },

  {
    blockType: 'heading',
    pattern: '/h4',
    checkBlockType: false,
    data(val) {
      return {
        text: val.replace('/h4', '').trim(),
        type: 4,
      }
    },
  },

  {
    blockType: 'heading',
    pattern: '/h5',
    checkBlockType: false,
    data(val) {
      return {
        text: val.replace('/h5', '').trim(),
        type: 5,
      }
    },
  },

  {
    blockType: 'heading',
    pattern: '/h6',
    checkBlockType: false,
    data(val) {
      return {
        text: val.replace('/h6', '').trim(),
        type: 6,
      }
    },
  },
]

export default function blockTransform(editor, val, blockIndex, blockId) {
  for (const index in items) {
    if (
          (!items[index].checkBlockType
                  || editor.getRef(blockId).getBlockType() !== items[index].blockType
          )
          && val.indexOf(items[index].pattern) !== -1
    ) {

      editor.replaceBlockWith({
        type: items[index].blockType,
        data: items[index].data(val),
      }, blockIndex, blockId)

      break;
    }
  }
}

<template>
  <sms-content class="day-overview" v-if="day" :title="day.date_formatted">
    <sms-grid-row>
      <sms-grid-col :size="[9, 8]">
        <sms-grid-row>
          <tasks-filters />
        </sms-grid-row>

        <sms-grid-row>
          <sms-grid-col>
            <sms-alert v-show="isToday && allTasksDone" class="mb-2" message="All tasks are done" type="success" />
          </sms-grid-col>
        </sms-grid-row>

        <sms-grid-row>
          <sms-card :body-padding="0">
            <div v-if="dayTasks.length > 0">
              <tasks-list
                      :color="colors.morning"
                      :day-tasks="tasksFilteredByTimeOfDay('morning')"
                      style="border-bottom: 1px solid #333"
              />
              <tasks-list
                      :color="colors.afternoon"
                      :day-tasks="tasksFilteredByTimeOfDay('afternoon')"
                      style="border-bottom: 1px solid #333"
              />
              <tasks-list :color="colors.evening" :day-tasks="tasksFilteredByTimeOfDay('evening')"></tasks-list>
            </div>

            <div class="p-2" v-else>No tasks</div>
          </sms-card>
        </sms-grid-row>
      </sms-grid-col>

      <sms-grid-col :size="[3, 4]">
        <days-navigation :day="day" style="margin-bottom: 20px;" />
        <sleep-log :day="day" />
        <weight-log v-if="isSunday" :day="day" />
      </sms-grid-col>
    </sms-grid-row>

    <sms-grid-row>
      <sms-grid-col :size="[6, 6]">
        <journal :day="day"></journal>
      </sms-grid-col>
      <sms-grid-col :size="[6, 6]">
        <sms-card>
          <my-editor :blocks="day.text_blocks ? day.text_blocks : []" @onChange="saveTextBlocks" />
        </sms-card>
      </sms-grid-col>
    </sms-grid-row>
  </sms-content>
</template>

<script>
import axios from "@/plugins/axios"
import TasksList from "@/views/pages/day-overview/tasks-list"
import SleepLog from "@/pages/DayOverview/SleepLog"
import WeightLog from "@/pages/DayOverview/WeightLog"
import Journal from "@/pages/DayOverview/Journal"
import DaysNavigation from "@/pages/DayOverview/DaysNavigation"
import MyEditor from "@/my-editor/MyEditor"
import SmsContent from "@/views/partials/Content"
import SmsGridRow from "@/views/components/GridRow"
import SmsCard from "@/views/components/Card"
import SmsGridCol from "@/views/components/GridColumn";
import TasksFilters from "@/views/pages/day-overview/tasks-filters";
import SmsAlert from "@/views/components/Alert";

export default {
  components: {
    SmsAlert,
    TasksFilters,
    SmsGridCol,
    SmsCard,
    SmsGridRow,
    SmsContent,
    MyEditor,
    DaysNavigation,
    Journal,
    WeightLog,
    SleepLog,
    TasksList,
  },
  data() {
    return {
      dayNotFound: false,
      nextDayDate: null,
    }
  },
  mounted() {
    this.loadDay(this.routeDay)
  },
  watch: {
    $route() {
      this.$store.commit('dayOverview/setDay', null)
      this.$store.commit('dayOverview/setDayTasks', [])
      this.loadDay(this.routeDay)
    },
    day(val) {
      if(val) {
        this.loadDayOverviewTasks()
      }
    },
  },
  computed: {
    day() {
      return this.$store.state.dayOverview.day
    },
    dayTasks() {
      return this.$store.state.dayOverview.dayTasks
    },
    filters() {
      return this.$store.state.dayOverview.filters
    },
    routeDay() {
      return this.$route.params.day
    },
    tomorrowDate() {
      return new Date(this.day.date)
    },
    isSunday() {
      return (new Date(this.day.date)).getDay() === 0
    },
    isToday() {
      return new Date(this.day.date).toDateString() === new Date().toDateString()
    },
    colors() {
      return {
        'morning': '#F3D231',
        'afternoon': '#5dba5d',
        'evening': '#d04444',
      }
    },
    allTasksDone() {
      if (this.dayTasks.length === 0) {
        return false
      }

      return this.dayTasks.filter(task => {
        return task.status === 'in_row'
      }).length === 0
    }
  },
  methods: {
    loadDay(date) {
      this.dayNotFound = false

      if (date === 'today') {
        axios.get('/day-overview/today').then(response => {
          this.$store.commit('dayOverview/setDay', response.data.data)
          this.nextDayDate = response.data.next_day_date

          this.loadDayOverviewTasks()
        }).catch(error => {
          if(error.response.status === 404) {
            this.dayNotFound = true
          }
        })
      } else {
        axios.get('/day-overview/' + date).then(response => {
          this.$store.commit('dayOverview/setDay', response.data.data)

          this.loadDayOverviewTasks()
        }).catch(error => {
          if(error.response.status === 404) {
            this.dayNotFound = true
          }
        })
      }
    },
    loadDayOverviewTasks() {
      this.$store.dispatch('dayOverview/loadDayOverviewTasks', this.day.date)
        .then(() => {
          this.setActiveDayTaskFromLocalStorage()
        })
    },
    tasksFilteredByTimeOfDay(timeOfDay) {
      return this.dayTasks.filter(x => x.task.time_of_day === timeOfDay).filter(x => {
        if (this.filters.done === 'any') {
          return true
        }

        if (this.filters.done) {
          return x.status === 'done'
        }

        if (!this.filters.done) {
          return x.status === 'in_row'
        }
      }).filter(x => {
        if (this.filters.chore === 'any') {
          return true
        }

        if (this.filters.chore) {
          return x.task.is_chore === 1
        }

        if (!this.filters.chore) {
          return x.task.is_chore === 0
        }
      }).filter(x => {
        if (this.filters.importance === 'any') {
          return true
        }

        if (this.filters.importance) {
          return x.task.is_important === 1
        }

        if (!this.filters.importance) {
          return x.task.is_important === 0
        }
      }).filter(x => {
        if (this.filters.outdoor === 'any') {
          return true
        }

        if (this.filters.outdoor) {
          return x.task.is_outdoor === 1
        }

        if (!this.filters.outdoor) {
          return x.task.is_outdoor === 0
        }
      })
    },
    saveTextBlocks(blocks) {
      axios.put('/day-overview/' + this.day.date + '/text', {blocks})
    },
    setFilter(type, value) {
      this.filters.done = value
    },
    setActiveDayTaskFromLocalStorage() {
      const activeTaskDayId = localStorage.getItem('active_day_task')

      if (!activeTaskDayId) {
        return
      }

      const dayTasks = this.dayTasks.filter(x => x.id === parseInt(activeTaskDayId))

      if (!dayTasks) {
        return
      }

      if (dayTasks[0]) {
        this.$store.commit('dayOverview/setActiveDayTask', dayTasks[0])
      }
    }
  },
}
</script>

<style>
.custom-control::before {
  border: 1px solid red;
}
</style>

<template>
  <aside class="app-sidebar sidemenu-sidebar">
    <ul class="side-menu">
      <li v-for="(item,index) in navItems"
          :key="index"
          :class="{'is-expanded': isNavOpen(item)}"
          class="slide"
      >
        <router-link
          v-if="!item.hasOwnProperty('subItems')"
          :to="item.route"
          class="side-menu__item sidemenu-icon"
        >
          <i class="side-menu__icon fe fe-home"></i>
          {{ item.label }}
        </router-link>

        <a
          v-if="item.hasOwnProperty('subItems')"
          @click.stop="toggleOpenNavItem(item)"
          href="javascript:void(0)"
          class="side-menu__item sidemenu-icon"
        >
          <i class="side-menu__icon fe fe-home"></i>
          <span class="side-menu__label">{{ item.label }}</span>
          <i class="angle fa fa-angle-right"></i>
        </a>

        <ul v-if="item.hasOwnProperty('subItems')" class="slide-menu">
          <li v-for="(subItem,index) in item.subItems" :key="index">
            <router-link :to="subItem.route" class="slide-item">
              {{ subItem.label }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </aside>
</template>

<script>
import navItems from '@/nav-items'
import PerfectScrollbar from 'perfect-scrollbar'
import '@/assets/plugins/sidemenu/icon-sidemenu'

function sidebarScrollBind() {
  const ps1 = new PerfectScrollbar('.app-sidebar', {
    useBothWheelAxes: false,
    suppressScrollX: false,
  });
}

export default {
  name: 'sms-left-sidebar',
  components: {},
  data() {
    return {
      navItems: navItems,
      navOpened: null,
    }
  },
  computed: {},
  mounted() {
    sidebarScrollBind()

    this.navOpened = localStorage.getItem('navName')
  },
  methods: {
    isNavOpen(item) {
      const navName = item.route ? item.route : item.label

      return this.navOpened === navName
    },
    toggleOpenNavItem(item) {
      const navName = item.route ? item.route : item.label

      this.navOpened = navName
      localStorage.setItem('navName', navName)
    }
  },
}
</script>

<template>
  <div v-if="page">
    <div>
      <my-editor :blocks="page.blocks" @onChange="savePage" />
    </div>
  </div>
</template>

<script>
import MyEditor from "@/my-editor/MyEditor"

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {MyEditor},
  data() {
    return {
      page: null,
    }
  },
  computed: {},
  mounted() {
    this.loadPage()
  },
  methods: {
    loadPage() {
      this.$store.dispatch('pages/loadPage', {id: this.id})
        .then(page => {
          this.page = page
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$store.dispatch('pages/createPage', {
              title: this.id,
              alias: this.id,
            }).then(page => {
              window.location.reload()
            })
          }
        })
    },
    savePage(blocks) {
      this.$store.dispatch('pages/savePageBlocks', {
        id: this.page.id,
        blocks: blocks,
      })
    },
  },
}
</script>

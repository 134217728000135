import axios from "@/plugins/axios"

export default {
  namespaced: true,
  state: {
    module: null,
    modules: [],
  },
  mutations: {
    setModules(state, modules) {
      state.modules = modules
    },
    setModule(state, module) {
      state.module = module
    }
  },
  actions: {
    loadModules({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/crud/modules').then(response => {
          commit('setModules', response.data.modules)
        })
      })
    },
    saveProperty({getters}, data) {
      return new Promise((resolve, reject) => {
        axios.put('/crud/' + data.module.alias + '/' + data.item.id + '/' + data.property, {
          [data.property]: data.value,
        }).then(response => {
          resolve(response)
        })
      })
    },
  },
  getters: {
    currentModule: (state) => state.modules[state.module]
  }
}

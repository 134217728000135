<template>
  <div>
    <template v-if="soundData.loaded">
      <player-timeline :marks="marks" :min="0" :max="songDuration" v-if="!hideSlider" />
    </template>

    <div v-if="currentTrack && !soundData.loaded">
      loading...
    </div>
  </div>
</template>

<script>
import PlayerTimeline from "./PlayerTimeline"

export default {
  components: {PlayerTimeline},
  data() {
    return {
      hideSlider: false,
    }
  },
  watch: {
    songDuration(val) {
      this.hideSlider = true

      this.$nextTick(x => {
        this.hideSlider = false
      })
    },
  },
  computed: {
    song() {
      return this.$store.state.guitar.song
    },
    songDuration() {
      return this.$store.state.guitar.soundData.duration
    },
    currentTrack() {
      return this.$store.state.guitar.currentTrack
    },
    soundData() {
      return this.$store.state.guitar.soundData
    },
    marks() {
      let marks = {"0": 0}

      let i = 1;
      for (let key in this.$store.state.guitar.song.segments) {
        console.log(this.$store.state.guitar.song.segments[key])

        if(!this.$store.state.guitar.song.segments[key].tracks[this.$store.getters["guitar/currentTrackId"]]) {
          break
        }

        marks[this.$store.state.guitar.song.segments[key].tracks[this.$store.getters["guitar/currentTrackId"]].start] = 'S' + i
        i++
      }

      marks[this.songDuration] = this.songDuration

      console.log(marks)

      return marks
    },
  },
  mounted() {
    this.loadDefaultTrack()
  },
  methods: {
    loadDefaultTrack() {
      let defaultTrack = this.getDefaultTrack()

      if (!defaultTrack) {
        return
      }

      this.$store.dispatch('guitar/loadTrack', defaultTrack)
    },
    getDefaultTrack() {
      const tracks = this.$store.state.guitar.song.tracks

      if (!tracks) {
        return null
      }

      let defaultTrackId = null
      for (let key in tracks) {
        if (tracks[key].is_default) {
          defaultTrackId = key
          break
        }
      }

      return defaultTrackId
    }
  },
}
</script>

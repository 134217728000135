<template>
  <div>
    <pre>
    - 1+1
    - Анжелика
    - Большой (про театр)
    - Блондинка в законе
    - Гордость и предубеждение
    - Дневник бриджит джонс
    - Джейн эйр
    - Дневник памяти
    - День сурка
    - Дьявол носит прада
    - Зачарованная
    - Золушка (старый фильм)
    - Иллюзионист
    - Красотка
    - Кейт и лео
    - Легкое поведение
    - Ледниковый период
    - Мачеха
    - Моя большая греческая свадьба
    - Отпуск по обмену
    - Письма к Джульетте
    - Прислуга
    - Предложение
    - Свадьба лучшего друга
    - Свадебный переполох
    - Сладкий ноябрь
    - Семьянин
    - Серия про борна
    - Спасти рядового Райна
    - Хороший год
    - Шрек, все части
      </pre>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  destroyed() {
  },
  methods: {},
}
</script>

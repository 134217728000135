<template>
    <div style="width: 100%;">
        <input
            @keyup.enter="searchNotes"
            v-model="searchQuery"
            type="text"
            class="form-control d-inline-block mb-1"
            placeholder="search"
        />
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {
        searchQuery: {
            get () {
                return this.$store.state.kb.searchQuery
            },
            set (value) {
                this.$store.commit('kb/setSearchQuery', value)
            }
        },
    },
    methods: {
        searchNotes() {
            if(!this.searchQuery) {
                return
            }

            this.loading = 'searching'

            this.$store.dispatch('kb/searchNote', this.searchQuery).then(data => {
                this.loading = null

                console.log('note tree length', data.notes_tree.length)

                this.notFound = data.notes_tree.length > 0
            })
        },
    }
}
</script>

<template>
  <sms-content title="Dashboard">
    <sms-grid-row>
      <sms-card>
        <sms-page id="dashboard" show-title />
      </sms-card>
    </sms-grid-row>
  </sms-content>
</template>

<script>
import SmsPage from "@/components/SmsPage"
import SmsContent from "@/views/partials/Content"
import SmsGridRow from "@/views/components/GridRow";
import SmsCard from "@/views/components/Card";

export default {
  components: {SmsCard, SmsGridRow, SmsContent, SmsPage},
  computed: {
    breadcrumbs() {
      return [
        {
          label: 'Dashboard',
        }
      ]
    },
  },
}
</script>

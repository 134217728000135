<template>
  <div>
    <h1>Организации</h1>

    <ul>
      <li v-for="org in list" :key="org.id">
        <router-link :to="{name: 'org-view', params: {id: org.id}}">{{ org.title }}</router-link>
      </li>
    </ul>

    <hr />

    <p>
      <input type="text" placeholder="name" v-model="newOrgName" />
      <a class="btn btn-primary btn-sm" @click="create">Create</a>
    </p>
  </div>
</template>

<script>
import axios from "../plugins/axios";

export default {
  components: {},
  data() {
    return {
      list: [],
      newOrgName: '',
      orgRelations: [],
    }
  },
  computed: {},
  mounted() {
    this.loadList()
    this.loadOrgRelations()
  },
  methods: {
    loadList() {
      axios.post('/note/search', {query: 'я > организация', level: 1}).then(response => {
        this.list = response.data.notes_tree
      })
    },
    create() {
      axios.post('/note/create', {
        title: [this.newOrgName],
        type: 'entity',
        relations: this.orgRelations,
        subtype: 'my_org',
      }).then(response => {
        this.list.push(response.data)
      })

      this.newOrgName = ''
    },
    loadOrgRelations() {
      axios.post('/note/search', {query: 'я', level: 1}).then(response => {
        this.orgRelations.push(response.data.notes_tree[0].id)
      })

      axios.post('/note/search', {query: 'организация', level: 1}).then(response => {
        this.orgRelations.push(response.data.notes_tree[0].id)
      })
    },
  },
}
</script>

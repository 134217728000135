import axios from "@/plugins/axios"

export default {
  namespaced: true,
  state: {
    tasks: [],
    viewingTask: {},
  },
  mutations: {
    setTasks(state, tasks) {
      state.tasks = tasks
    },
    setViewingTask(state, task) {
      state.viewingTask = task
    }
  },
  actions: {
    create({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('/task', data).then(response => {
          resolve(response.data.data)
        })
      })
    },
    load({commit}, type) {
      return new Promise((resolve, reject) => {
        axios.get('/task/list/' + type).then(response => {
          commit('setTasks', response.data.data)
          resolve(response.data.data)
        })
      })
    },
    loadOne({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.get('/task/' + data.id).then(response => {
          commit('setViewingTask', response.data.data)
          resolve(response.data.data)
        })
      })
    },
    delete({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.delete('/task/' + data.id).then(response => {
          resolve(response.data.data)
        })
      })
    },
    done({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put('/task/' + data.id + (data.done ? '/done' : '/undone')).then(response => {
          resolve(response.data.data)
        })
      })
    },
    undone({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put('/task/' + data.id + '/undone').then(response => {
          resolve(response.data.data)
        })
      })
    },
    rename({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put('/task/' + data.id + '/name', {name: data.name}).then(response => {
          resolve(response.data.data)
        })
      })
    },
    setDueDate({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put('/task/' + data.id + '/due-date', {due_date: data.dueDate})
          .then(response => {
            resolve(response.data.data)
          })
      })
    },
    setRecurring({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put('/task/' + data.id + '/recurring', {recurring: data.recurring})
                .then(response => {
                  resolve(response.data.data)
                })
      })
    },
    setProperty({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put('/task/' + data.id + '/' + data.property, data.payload).then(response => {
            resolve(response.data.data)
          })
      })
    },
    restore({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('/task/' + data.id + '/restore').then(response => {
          resolve(response.data.data)
        })
      })
    },
  }
}

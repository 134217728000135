<template>
  <div
      :class="[
        'col-xl-' + xlSize,
        'col-lg-' + lgSize,
        'col-md-' + mdSize,
        'col-sm-' + smSize,
      ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'sms-grid-col',
  props: {
    size: {
      type: Array,
      default() {
        return [12, 12, 12, 12]
      }
    }
  },
  computed: {
    xlSize() {
      return this.size[0]
    },
    lgSize() {
      return this.size[1] ? this.size[1] : 12
    },
    mdSize() {
      return this.size[2] ? this.size[2] : 12
    },
    smSize() {
      return this.size[3] ? this.size[3] : 12
    },
  },
}
</script>

<template>
  <div class="my-block-editor" v-hotkey="keymap">
    <draggable v-model="editorBlocks" handle=".block-handle" @end="emitOnChange">
      <div v-for="(block,index) in editorBlocks" :key="'block-wrapper-' + block.id" style="position: relative;">
        <span class="block-handle"
              style="position: absolute; left: -20px; top: -5px; font-size: 20px; cursor: pointer;"
              :class="{'block-selected': selectedBlocks.includes(block.id)}"
              @click="toggleBlockSelected(block.id)"
        >
          <b-icon-grip-vertical />
        </span>
        <component
                :is="block.type"
                :data="block.data"
                :editor="editor"
                :id="block.id"
                :index="index"
                :ref="'block-' + block.id"
        />
      </div>
    </draggable>

    <div><a href="#" @click.prevent="addNewBlock">add block</a></div>
  </div>
</template>

<script>
import Components from './components'
import blockTransform from "@/my-editor/block-transform"
import Draggable from 'vuedraggable'

export default {
  components: Object.assign(Components, {
    Draggable,
  }),
  props: {
    blocks: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      editorBlocks: this.blocks,
      selectedBlocks: [],
    }
  },
  computed: {
    editor() {
      return this
    },
    keymap () {
      return {
        'delete': this.deleteSelectedBlocks,
      }
    },
  },
  mounted() {},
  methods: {
    deleteSelectedBlocks() {
      if (this.selectedBlocks.length === 0) {
        return
      }

      this.selectedBlocks.forEach(blockId => {
        let blockIndex = null
        for (let index in this.editorBlocks) {
          if (this.editorBlocks[index].id === blockId) {
            blockIndex = index
            break
          }
        }

        const block = this.getRef(blockId)

        if (typeof block.onDelete === 'function') {
          block.onDelete()
        }

        this.editorBlocks.splice(blockIndex, 1)
      })

      this.$nextTick(x => {
        this.emitOnChange()
      })

      this.selectedBlocks = []
    },
    defaultBlock() {
      return {
        type: 'paragraph',
        data: {
          text: '',
        },
      }
    },
    addBlock(block, afterIndex) {
      console.log('block added', block, afterIndex)

      block.id = this.uuidv4()

      if (afterIndex >= 0) {
        // console.log('index', afterIndex)

        this.editorBlocks.splice(afterIndex + 1, 0, block)
      } else {
        this.editorBlocks.push(block)
      }

      this.$nextTick(() => {
        this.getRef(block.id).focus()

        this.emitOnChange()
      })
    },
    deleteBlock(index, blockId, focusOnBlock) {
      if (this.editorBlocks.length > 1) {
        if (!focusOnBlock || focusOnBlock === 'prev') {
          this.focusPrev(index)
        } else {
          this.focusNext(index)
        }
      }

      const block = this.getRef(blockId)

      if (typeof block.onDelete === 'function') {
        block.onDelete()
      }

      // if (block.onDelete) {
      //   block.onDelete()
      // }

      // console.log('block deleted', index)

      this.$nextTick(() => {
          this.editorBlocks.splice(index, 1)

          this.$nextTick(() => {
            this.emitOnChange()
          })
      })
    },
    replaceBlockWith(block, replaceBlockIndex, replacedBlockId) {
      this.editor.deleteBlock(replaceBlockIndex, replacedBlockId, 'next')
      this.editor.addBlock(block, replaceBlockIndex)
    },
    addNewBlock() {
      this.addBlock(this.defaultBlock())
    },
    toggleBlockSelected(blockId) {
      if (this.selectedBlocks.includes(blockId)) {
       this.selectedBlocks.splice(this.selectedBlocks.indexOf(blockId), 1)
      } else {
        this.selectedBlocks.push(blockId)
      }
    },
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },
    focusPrev(index, blockId) {
      if (index > 0) {
        this.getRef(this.editorBlocks[index - 1].id).focus()
      }
    },
    focusNext(index, blockId) {
      if (this.editorBlocks.length > index + 1) {
        this.getRef(this.editorBlocks[index + 1].id).focus()
      }
    },
    getRef(blockId) {
      return this.$refs['block-' + blockId][0]
    },
    placeCaretAtEnd(el) {
      el.focus();
      if (typeof window.getSelection != "undefined"
              && typeof document.createRange != "undefined") {
        var range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof document.body.createTextRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
      }
    },
    emitOnChange() {
      let blocks = []
      for (let index in this.editorBlocks) {
        // console.log('block id', index, this.editorBlocks[index])

        let block = this.getRef(this.editorBlocks[index].id)

        let data = block.getBlockData()

        // если блок возвратит null
        // то не сохраняем его
        // такое может быть с асинхронными блоками

        if (data) {
          blocks.push({
            id: this.editorBlocks[index].id,
            type: block.getBlockType(),
            data: block.getBlockData(),
          })
        }
      }

      this.$emit('onChange', blocks)
    },
    changeBlock(val, blockIndex, blockId) {
      blockTransform(this, val, blockIndex, blockId)
    }
  },
}
</script>

<style scoped>
.block-selected {
  color: red;
}
</style>

<template>
  <sms-content v-if="project.name" :title="project.name">
    <sms-grid-row>
      <sms-card :size="[6]">
        <div>{{ project.status }}</div>

        <div>
          <select v-model="project.status" @change="changeProjectStatus">
            <option value="in_row">in row</option>
            <option value="active">active </option>
            <option value="completed">completed</option>
          </select>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <h3>Задачи</h3>

            <ul>
              <li v-for="task in tasks" :key="task.id">
                <strong :class="{'task-done': task.is_done}">{{ task.name }}</strong>
              </li>
            </ul>
          </div>
        </div>
      </sms-card>
      <sms-card :size="[6]">
        <my-editor v-if="project.text_blocks" :blocks="project.text_blocks" @onChange="saveText" />
      </sms-card>
    </sms-grid-row>

    <hr />

    <sms-grid-row>
      <a @click.prevent="deleteProject" href="#" class="btn btn-sm btn-danger">delete</a>
    </sms-grid-row>

  </sms-content>
</template>

<script>
import axios from "@/plugins/axios"
import MyEditor from "@/my-editor/MyEditor";
import SmsContent from "@/views/partials/Content";
import SmsGridRow from "@/views/components/GridRow";
import SmsCard from "@/views/components/Card";

export default {
  components: {SmsCard, SmsGridRow, SmsContent, MyEditor},
  data() {
    return {
      project: {},
      tasks: [],
    }
  },
  computed: {},
  mounted() {
    this.loadProject()
  },
  methods: {
    loadProject() {
      axios.get('/task/project/' + this.$route.params.id)
        .then(response => {
          this.project = response.data.project

          this.loadTasks(this.project.id)
        })
    },
    deleteProject() {
      if (!confirm('Delete?')) {
        return
      }
    },
    loadTasks(projectId) {
      axios.get('/task/list?project_id=' + projectId)
              .then(response => {
                this.tasks = response.data.tasks
              })
    },
    changeProjectStatus() {
      axios.put('/task/project/' + this.project.id + '/status', {
        status: this.project.status
      }).then(response => {
        this.rpoejct = response.data.project
      })
    },
    saveText(blocks) {
      axios.put('/task/project/' + this.project.id + '/text', {
        blocks
      }).then(response => {
        this.rpoejct = response.data.project
      })
    },
  },
}
</script>

<style scoped>
.task-done {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import Store from './store/index'
import router from './router/router'
import VueHotkey from 'v-hotkey'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VCalendar from 'v-calendar'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueHotkey)
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />,
})

const store = Store

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')

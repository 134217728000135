export default {
  loggedIn() {
    return localStorage.getItem('auth_token')
  },
  logIn(token) {
    localStorage.setItem('auth_token', token)
  },
  logOut() {
    localStorage.removeItem('auth_token')
  },
  token() {
    return localStorage.getItem('auth_token')
  },
}

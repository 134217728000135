<template>
  <sms-card class="tasks-filters">
    <a
      v-for="(filter, index) in availableFilters"
      @click.prevent="toggleFilter(filter)"
      :key="index"
      href="#"
      :class="{'selected': filters[filter.alias] !== filter.defaultValue}"
      class="mr-4"
    >
      {{ filter.label }}: {{ filters[filter.alias] }}
    </a>

    <div class="float-right">
      <a @click.prevent="resetFilters" title="reset filters" class="mr-3" href="#"><sms-icon name="fa fa-times-circle" /></a>
      <a @click.prevent="reloadTasks" title="reload tasks" href="#"><sms-icon name="fa fa-refresh" /></a>
    </div>
  </sms-card>
</template>

<script>
import SmsCard from "@/views/components/Card";
import SmsIcon from "@/views/components/Icon";

export default {
  components: {SmsIcon, SmsCard},
  computed: {
    filters() {
      return this.$store.state.dayOverview.filters
    },
    availableFilters() {
      return [
        {
          alias: 'done',
          label: 'done',
          defaultValue: 'any',
        },
        {
          alias: 'chore',
          label: 'chore',
          defaultValue: 'any',
        },
        {
          alias: 'outdoor',
          label: 'outdoor',
          defaultValue: 'any',
        },
        {
          alias: 'importance',
          label: 'importance',
          defaultValue: 'any',
        },
      ]
    }
  },
  methods: {
    reloadTasks() {
      this.$store.dispatch('dayOverview/updateOverviewTasks')
    },
    toggleFilter(filter) {
      switch (filter.alias) {
        case 'done':
          this.toggleDoneFilter();
          break;

        case 'chore':
          this.toggleChoreFilter();
          break;

        case 'outdoor':
          this.toggleOutdoorFilter();
          break;

        case 'importance':
          this.toggleImportanceFilter();
          break;
      }
    },
    toggleDoneFilter() {
      let doneFilterValue = 'any'

      switch (this.filters.done)
      {
        case "any":
          doneFilterValue = false
          break

        case false:
          doneFilterValue = true;
          break
      }

      this.filters.done = doneFilterValue

      this.$store.commit('dayOverview/setFilters', this.filters)
    },
    toggleChoreFilter() {
      let choreFilterValue = 'any'

      switch (this.filters.chore)
      {
        case "any":
          choreFilterValue = true
          break

        case true:
          choreFilterValue = false;
          break
      }

      this.filters.chore = choreFilterValue

      this.$store.commit('dayOverview/setFilters', this.filters)
    },
    toggleOutdoorFilter() {
      let outdoorFilterValue = 'any'

      switch (this.filters.outdoor)
      {
        case "any":
          outdoorFilterValue = true
          break

        case true:
          outdoorFilterValue = false;
          break
      }

      this.filters.outdoor = outdoorFilterValue
      this.$store.commit('dayOverview/setFilters', this.filters)
    },
    toggleImportanceFilter() {
      let importanceFilterValue = 'any'

      switch (this.filters.importance)
      {
        case "any":
          importanceFilterValue = true
          break

        case true:
          importanceFilterValue = false;
          break
      }

      this.filters.importance = importanceFilterValue

      this.$store.commit('dayOverview/setFilters', this.filters)
    },
    resetFilters() {
      this.$store.commit('dayOverview/resetFilters')
    }
  },
}
</script>

<style>
  .tasks-filters a.selected {
    font-weight: bold;
    text-decoration: underline;
  }
</style>

<template>
  <sms-content v-if="contact" :title="contact.title">
    <sms-grid-row>
      <sms-card>
        <h3>Relations</h3>

        <ul>
          <li v-for="relation in contact.relations" :key="relation.id">
            {{ relation.title }}
          </li>
        </ul>

        <hr />

        <a class="btn btn-danger btn-sm" @click="deleteContact">delete</a>
      </sms-card>
    </sms-grid-row>
  </sms-content>
</template>

<script>
import axios from "@/plugins/axios"
import SmsContent from "@/views/partials/Content"
import SmsGridRow from "@/views/components/GridRow"
import SmsCard from "@/views/components/Card"

export default {
  components: {SmsCard, SmsGridRow, SmsContent},
  data() {
    return {
      contact: null,
    }
  },
  computed: {},
  mounted() {
    this.loadContact()
  },
  methods: {
    loadContact() {
      axios.get('/note/' + this.$route.params.id).then(response => {
        this.contact = response.data
      })
    },
    deleteContact() {
      if (!confirm('Delete?')) {
        return
      }

      axios.delete('/note/delete/' + this.contact.id).then(response => {
        this.$router.push({name: 'contacts-list'})
      })
    },
  },
}
</script>

import Paragraph from "@/my-editor/blocks/Paragraph"
import TodoItem from "@/my-editor/blocks/TodoItem"
import Divider from "@/my-editor/blocks/Divider"
import Subpage from "@/my-editor/blocks/Subpage"
import Heading from "@/my-editor/blocks/Heading"

export default {
  Paragraph,
  Divider,
  TodoItem,
  Subpage,
  Heading,
}

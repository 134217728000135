<template>
  <div>
    <a class="btn btn-sm btn-success" @click="sendCode" v-if="!codeSent">Send code</a>

    <input type="text" @keypress.enter="getToken" v-model="code" v-if="codeSent" />
  </div>
</template>

<script>
import axios from "../plugins/axios"
import auth from "../plugins/auth"


export default {
  components: {},
  data() {
    return {
      codeSent: false,
      code: null,
    }
  },
  computed: {},
  mounted() {
  },
  destroyed() {
  },
  methods: {
    getToken() {
      if (!this.code) {
        return
      }

      axios.post('/auth/token', {
        code: this.code
      }).then(response => {
        if (response.data.success) {
          auth.logIn(response.data.token)

          window.location.href = '/'
        } else {
          alert('Wrong code')

          this.codeSent = false
          this.code = ''
        }
      })
    },
    sendCode() {
      axios.post('/auth/code').then(response => {
        this.codeSent = true
      })
    },
  },
}
</script>

<template>
    <div :class="[indent ? 'indent' : '', type === 'parent' ? 'parent-note' : 'child-note']">
        <div v-for="subNote in subNotes" v-show="maxLevel > note.level" :key="subNote.id">
            <note :note="subNote" />

            <note-tree
                :indent="true"
                :type="type"
                :max-level="maxLevel"
                :note="subNote"
            />
        </div>
    </div>
</template>

<script>
import Note from './Note'
import NoteTree from './NoteTree'

export default {
    name: 'NoteTree',
    components: {Note, NoteTree},
    props: {
        note: {
            type: Object
        },
        maxLevel: {
            type: Number,
            default: 5,
        },
        type: {
            type: String,
            default: 'child',
        },
        indent: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            showInput: false,
        }
    },
    computed: {
        subNotes() {
            if(!this.note) {
                return []
            }

            return this.type === 'child' ? this.note.childs : this.note.roots
        }
    },
    methods: {

    }
}
</script>

<style scoped>
.indent {
    margin-left: 20px;
}
.parent-note div {
}
</style>

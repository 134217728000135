<template>
  <sms-content title="Tasks">
    <sms-grid-row>
      <sms-grid-col :size="[3, 3]">
        <sms-card :body-padding="0">
          <div class="list-group list-group-transparent mb-0 mail-inbox">
            <router-link
                    v-for="(navItem,index) in nav"
                    :key="index"
                    :class="{active: isCurrentPage(navItem.route)}"
                    :to="{name: navItem.route}"
                    class="list-group-item list-group-item-action d-flex align-items-center"
            >
              <span class="icon mr-3"><i class="fe fe-inbox"></i></span>{{ navItem.label }}
              <!--            <span class="ml-auto badge badge-success">14</span>-->
            </router-link>
          </div>
        </sms-card>
      </sms-grid-col>

      <sms-grid-col :size="[9, 9]">
        <sms-card :body-padding="0">
          <tasks-list :tasks="tasks"></tasks-list>

          <div v-show="tasks.length === 0">No tasks</div>
        </sms-card>
      </sms-grid-col>
    </sms-grid-row>
  </sms-content>
</template>

<script>
import TasksList from "@/blocks/tasks/TasksList"
import SmsContent from "@/views/partials/Content";
import SmsGridRow from "@/views/components/GridRow";
import SmsCard from "@/views/components/Card";
import SmsGridCol from "@/views/components/GridColumn";

const pathNameToRoute = {
  'tasks-inbox': 'inbox',
  'tasks-tomorrow': 'tomorrow',
  'tasks-week': 'week',
  'tasks-all': 'all',
  'tasks-no-due-date': 'no-due-date',
  'tasks-deleted': 'deleted',
}

export default {
  components: {SmsGridCol, SmsCard, SmsGridRow, SmsContent, TasksList},
  computed: {
    tasks() {
      return this.$store.state.tasks.tasks
    },
    nav() {
      return [
        {
          route: 'tasks-inbox',
          label: 'Inbox',
          icon: 'fe fe-inbox',
        },
        {
          route: 'tasks-tomorrow',
          label: 'Tomorrow',
          icon: 'fe fe-send',
        },
        {
          route: 'tasks-week',
          label: 'Week',
          icon: 'fe fe-send',
        },
        {
          route: 'tasks-no-due-date',
          label: 'No due',
          icon: 'fe fe-send',
        },
        {
          route: 'tasks-all',
          label: 'All',
          icon: 'fe fe-send',
        },
        {
          route: 'tasks-deleted',
          label: 'Deleted',
          icon: 'fe fe-trash-2',
        },
      ]
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    $route(val) {
      this.init()
    },
  },
  methods: {
    isCurrentPage(routeName) {
      return routeName === this.$route.name
    },
    init() {
      if (this.$route.name === 'tasks') {
        this.$router.push({name: 'tasks-tomorrow'})
        return
      }

      this.$store.dispatch('tasks/load', pathNameToRoute[this.$route.name])
    },
  },
}
</script>

<style scoped>
  .nav li {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .nav li.active {
    font-weight: bold;
    text-decoration: underline;
  }
</style>

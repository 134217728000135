<template>
  <div v-if="soundData.loaded">
    <h5>Track segments <a href="#" @click.prevent="reloadTrack">[reload]</a></h5>

    <div class="row">
      <div v-for="segment in segments"
           :key="segment.id" class="col-lg-12"
           style="border: 1px dotted #777; padding: 4px 10px ;"
      >
        <div v-for="track in tracks" :key="track.id" v-show="track.id === currentTrack">
          <div class="row">
            <div class="col-lg-12">
              <span class="mr-2">
                {{ segment.name }}.
              </span>


              <input type="text" style="width: 70px;"
                     :value="segment.tracks[track.id] ? segment.tracks[track.id].start : ''"
                     @change="setSegmentPosition('start', segment.id, $event.target.value)"
              />
              <input type="submit" value="set" @click="setSegmentPosition('start', segment.id)" />

              -

              <input type="text" style="width: 70px;"
                     :value="segment.tracks[track.id] ? segment.tracks[track.id].end : ''"
                     @change="setSegmentPosition('end', segment.id, $event.target.value)"
              >
              <input type="submit" value="set" @click="setSegmentPosition('end', segment.id)" />

              <span class="ml-3">
                <button
                  v-if="isSegmentLearnt(segment.id)"
                  @click="setSegmentLearn(segment.id, false)"
                  class="btn btn-success btn-sm"
                  style="color: black;"
                >
                  Выучен
                </button>

                <button
                  v-else class="btn btn-warning btn-sm"
                  @click="setSegmentLearn(segment.id, true)"
                  style="color: black;"
                >
                  Не выучен
                </button>
              </span>
            </div>
          </div>

          <br />

          <speed-control style="margin-bottom: 20px;" :segment-id="segment.id" :speed="segment.speed"></speed-control>

          <div v-if="segment.tracks[track.id]">
<!--            {{ currentPlayingSprite }} - {{ segment.id}}-->

            <player-timeline
                    disabled="true"
                    v-if="segment.tracks[track.id] && currentPlayingSprite === segment.id"
                    :marks="{}"
                    :min="parseFloat(segment.tracks[track.id].start)"
                    :max="parseFloat(segment.tracks[track.id].end)"
            />
            <player-controls :sprite-name="segment.id" :show-time="false" />
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div>
      <h6>Add segment</h6>
      <input type="text" v-model="newSegmentName" /> <input type="submit" value="add" @click="addSegment" />
    </div>
  </div>
</template>

<script>
import PlayerTimeline from "./PlayerTimeline"
import PlayerControls from "./PlayerControls"
import SpeedControl from "./SpeedControl"

export default {
  components: {SpeedControl, PlayerControls, PlayerTimeline},
  data() {
    return {
      newSegmentName: '',
      segmentNumber: 1,
    }
  },
  computed: {
    segmentsLearn() {
      return this.$store.state.guitar.song.segments_learn
    },
    segments() {
      return this.$store.state.guitar.song.segments
    },
    soundData() {
      return this.$store.state.guitar.soundData
    },
    tracks() {
      return this.$store.state.guitar.song.tracks
    },
    currentTrack() {
      return this.$store.getters["guitar/currentTrackId"]
    },
    currentPlayingSprite() {
      return this.$store.state.guitar.currentPlayingSprite
    },
  },
  methods: {
    addSegment() {
      this.$store.dispatch('guitar/addTrackSegment', {name: this.newSegmentName})
      this.newSegmentName = ''
    },
    setSegmentPosition(positionType, segmentId, value) {
      let data = {
        segmentId, positionType
      }

      if (value) {
        data.value = value
      }

      this.$store.dispatch('guitar/setTrackSegmentPosition', data)
    },
    reloadTrack() {
      this.$store.dispatch('guitar/reloadSound')
    },
    isSegmentLearnt(segmentId) {
      return this.segmentsLearn && this.segmentsLearn.includes(segmentId)
    },
    setSegmentLearn(segmentId, isLearn) {
      console.log({
        segmentId: segmentId,
        isLearn: isLearn
      })

      this.$store.dispatch('guitar/setSegmentLearn', {
        segmentId: segmentId,
        isLearn: isLearn
      })
    }
  },
}
</script>

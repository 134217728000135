<template>
    <div @mouseover="showSearch = true" @mouseleave="showSearch = false" class="note" style="margin-bottom: 1rem">
        <div :class="['note-type-' + note.type, isSticky ? 'note-sticky' : '']" >
            <span :class="[highlight ? 'note-highlight' : '']">{{ note.title }}</span>

            <span v-show="showSearch" class="ml-2">
                <b-icon-search class="mr-1" style="cursor: pointer;" @click.stop="search">
                </b-icon-search>

                <b-icon-pen class="mr-1" style="cursor: pointer;" @click.stop="update">
                </b-icon-pen>

                <b-icon-plus class="mr-1" style="cursor: pointer;" @click.stop="addNoteAsRelation">
                </b-icon-plus>

                <b-icon-sticky v-show="!isSticky" class="mr-1" style="cursor: pointer;" @click.stop="stick">
                </b-icon-sticky>

                <b-icon-sticky-fill v-show="isSticky" class="mr-1" style="cursor: pointer;" @click.stop="unstick">
                </b-icon-sticky-fill>

                <b-icon-trash class="mr-1" style="cursor: pointer;" @click.stop="remove">
                </b-icon-trash>
            </span>
        </div>

        <div v-if="showRelations">
            <span v-for="item in note.roots" :key="item.id" v-show="note.level === 1">
              <span style="cursor: pointer; border-bottom: 1px solid green; margin-right: 10px;"
                    :title="item.title"
                    @click="goToRoot(item)"
              >{{ item.title }}</span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        highlight: {
            type: Boolean,
            default: false,
        },
        note: {
            type: Object
        },
        sticked: {
            type: Boolean,
            default: false
        },
        showActions: {
            type: Boolean,
            default: false
        },
        showRelations: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            showSearch: false
        }
    },
    computed: {
        isSticky() {
            return this.$store.state.kb.stickyNotes.indexOf(this.note) >= 0
        }
    },
    methods: {
        stick() {
            this.$store.commit('kb/addStickyNote', this.note)
        },
        unstick() {
            this.$store.commit('kb/removeStickyNote', this.note)
        },
        remove() {
            if(!confirm("Delete note?")) {
                return
            }

            this.$store.dispatch('kb/deleteNote', this.note)
        },
        update() {
            console.log('update note', this.note)

            this.$store.dispatch('kb/loadNote', this.note.id).then(note => {
                console.log('loaded note', note)

                this.$store.commit('kb/updateEditingNote', {
                    id: note.id,
                    title: note.type === 'entity' ? '# ' + note.title : note.title,
                    editingTitle: (note.type === 'entity' ? '# ' + note.title : note.title)
                        + (note.title_id.length > 1 ? ' | ' : '') + note.title_id.splice(1).join(' | '),
                    type: note.type,
                    relations: note.relations,
                })
            })
        },
        search() {
            this.$store.dispatch('kb/searchNote', this.note.title)
        },
        addNoteAsRelation() {
            this.$store.commit('kb/addNewNoteRelation', this.note)
        },
        goToRoot(note) {
          this.$store.dispatch('kb/searchNote', note.title)
        }
    }
}
</script>

<style scoped>
.note-type-entity {
    font-weight: bold;
    font-size: 20px;
}
.note-type-entity .note-highlight {
    font-weight: bold;
    font-size: 24px;
}
.note-type-fact {
    font-size: 16px;
}
.sticky-notes-container .note-sticky {
    font-weight: normal;
    font-size: 18px;
}
.note-highlight {
    border-bottom: 1px solid green;
}
.parent-note .note p {
    font-size: 16px;
}
</style>

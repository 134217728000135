<template>
  <div class="content-editable-input"
       contenteditable="true"
       @keyup="handleInput"
       @blur="onBlur"
       :ref="refName"
  >{{ content }}</div>
</template>

<script>
function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export default {
  props: ['value'],
  data() {
    return {
      // event: {},
      // which: '',
      // key: '',
      refName: 'test' + getRandomArbitrary(0, 1000000000),
      content: this.value,
      prevContent: this.value,
    }
  },
  mounted() {
    this.$refs[this.refName].addEventListener('keypress', (e) => {
      const text = e.target.innerText.replace(/^\n|\n$/g, '')

      // console.log('which', e)

      // let data = []
      // for (let index in e) {
      //   // if (Array.isArray(e[index])) {
      //   //   continue;
      //   // }
      //
      //   console.log('test', index, typeof (e[index]))
      //
      //   const validTypes = ['number', 'string', 'boolean'];
      //
      //   if (!validTypes.includes(typeof (e[index]))) {
      //     continue
      //   }
      //
      //   let item = {}
      //   item[index] = e[index]
      //
      //   data.push(item)
      // }

      // this.event = data

      // this.which = e.which

      if (e.key === 'Enter') {
        e.preventDefault()

        if (e.ctrlKey) {
          this.onCtrlEnter(text)
        } else {
          this.onEnter(text)
        }
      }
    })

    this.$refs[this.refName].addEventListener('keydown', (e) => {
      // console.log('which key down', e)

      if (e.key === "ArrowUp") {
        e.preventDefault()
      }
    })
  },
  destroyed() {
    if (this.$refs[this.refName]) {
      this.$refs[this.refName].removeEventListener('keypress')
    }
  },
  methods: {
    handleInput(e) {
      // this.key = e.key
      // console.log('key', e)

      const text = e.target.innerText.replace(/^\n|\n$/g, '')

      if (e.key === "Delete") {
        this.onDelete(text)
      }

      if (e.key === "Backspace") {
        this.onBackspace(text)
      }

      if (e.key === "ArrowUp") {
        this.onArrowUp(text)
      }

      if (e.key === "ArrowDown") {
        this.onArrowDown(text)
      }

      this.$emit('input', text)
      this.prevContent = text
    },
    onEnter(text) {
      // this.$refs[this.refName].blur()

      this.$emit('onEnter', text)
    },
    onCtrlEnter(text) {
      this.$emit('onCtrlEnter', text)
    },
    onDelete(text) {
      if (this.prevContent === '') {
        this.$emit('onEmptyAndDelete', text)
      }

      this.$emit('onDelete', text)
    },
    onBackspace(text) {
      if (this.prevContent === '') {
        this.$emit('onEmptyAndBackspace', text)
      }

      this.$emit('onBackspace', text)
    },
    onArrowUp(text) {
      this.$emit('onArrowUp', text)
    },
    onArrowDown(text) {
      this.$emit('onArrowDown', text)
    },
    onBlur(e) {
      this.$emit('onBlur', e.target.innerText.replace(/^\n|\n$/g, ''))
    },
  },
}
</script>

<style scoped>
.content-editable-input {
  outline: none;
}
.content-editable-input::selection {
  background-color: #abcde8;
  color: #0d0d0d;
}
</style>

<template>
  <sms-content>
    <sms-grid-row>
      <sms-card>
        <sms-page v-if="pageId" :id="pageId" show-title></sms-page>
      </sms-card>
    </sms-grid-row>
  </sms-content>
</template>

<script>
import SmsPage from "@/components/SmsPage"
import SmsContent from "@/views/partials/Content";
import SmsGridRow from "@/views/components/GridRow";
import SmsCard from "@/views/components/Card";

export default {
  components: {
    SmsCard,
    SmsGridRow,
    SmsContent,
    SmsPage,
  },
  data() {
    return {
      pageId: this.$route.params.id,
    }
  },
  computed: {},
  watch: {
    $route(to, from) {
      this.pageId = null

      this.$nextTick(() => {
        this.pageId = this.$route.params.id
      })
    },
  },
  mounted() {
    // this.loadPage()
  },
  methods: {
    // loadPage() {
    //   this.$store.dispatch('pages/loadPage', {id: this.$route.params.id}).then(page => {
    //     this.page = page
    //   })
    // },
    // deletePage() {
    //   this.$store.dispatch('pages/deletePage', {page: this.page})
    //     .then(response => {
    //       this.$store.dispatch("pages/loadPages")
    //
    //       this.$router.push({name: 'dashboard'})
    //     })
    // },
    // savePageTitle(value) {
    //   if (value === this.page.title) {
    //      return
    //   }
    //
    //   this.page.title = value
    //
    //   this.$store.dispatch('pages/savePageTitle', {id: this.page.id, title: this.page.title})
    //     .then(response => {
    //       this.$store.dispatch('pages/loadPages')
    //     })
    // },
  }
}
</script>

<template>
  <sms-card header-title="Journal">
    <ul>
      <li v-for="note in notes" :key="note.id">
        {{ note.title }}
      </li>
    </ul>

    <input type="text" v-model="note" @keypress.enter="createNote">
  </sms-card>
</template>

<script>
import axios from "../../plugins/axios";
import SmsCard from "@/views/components/Card";

export default {
  components: {SmsCard},
  props: ['day'],
  data() {
    return {
      notes: [],
      note: '',
    }
  },
  mounted() {
    this.loadNotes()
  },
  methods: {
    loadNotes() {
      axios.get('/journal/items/' + this.day.date).then(response => {
        this.notes = response.data.data
      })
    },
    createNote() {
      axios.post('/journal/item', {
        text: this.note,
        date: this.day.date,
      }).then(response => {
        this.loadNotes()
        this.note = ''
      })
    },
  },
}
</script>

<template>
  <sms-card :header-title="songName" :body-padding="0">
    <div v-show="infoMode === 'view'" class="table-responsive">
      <table class="table card-table table-vcenter text-nowrap mb-0">
        <tr>
          <td>{{ tuning }} {{ tuningNote }}</td>
        </tr>
      </table>
    </div>

    <div v-show="infoMode === 'edit'">
      <table class="table card-table table-vcenter text-nowrap mb-0">
        <tr>
          <input class="form-control" type="text" v-model="bandName" @change="updateSong" />
        </tr>
        <tr>
          <input class="form-control" type="text" v-model="name" @change="updateSong" />
        </tr>
        <tr>
          <select class="form-control" v-model="tuning" @change="updateSong">
            <option value="standard">Standard</option>
            <option value="drop">Drop</option>
          </select>
        </tr>
        <tr>
          <select class="form-control" v-model="tuningNote" @change="updateSong">
            <option>Base open string note</option>
            <option v-for="(note,index) in notes" :key="index" :value="note">{{ note }}</option>
          </select>
        </tr>
      </table>
    </div>

    <template #options>
      <a
        @click.prevent="infoMode = infoMode === 'view' ? 'edit' : 'view'"
        href="#"
        class="card-options-collapse"
        data-toggle="card-collapse"
      ><i class="fa fa-cog"></i></a>
    </template>
  </sms-card>
</template>

<script>
import SmsCard from "@/views/components/Card";

export default {
  components: {SmsCard},
  data() {
    return {
      bandName: '',
      name: '',
      tuning: '',
      tuningNote: '',
      infoMode: 'view',
    }
  },
  mounted() {
    this.bandName = this.song.band_name
    this.name = this.song.name
    this.tuning = this.song.tuning
    this.tuningNote = this.song.tuningNote
  },
  computed: {
    song() {
      return this.$store.state.guitar.song
    },
    songName() {
      return this.song.band_name + ' - ' + this.song.name
    },
    notes() {
      return [
        'E', 'D#', 'D', 'C#', 'C', 'B', 'A#', 'A', 'G#', 'G', 'F#', 'F',
      ]
    }
  },
  methods: {
    updateSong() {
      this.$store.dispatch('guitar/updateSong', {
        name: this.name,
        band_name: this.bandName,
        tuning: this.tuning,
        tuningNote: this.tuningNote,
      })
    },
  },
}
</script>

<template>
  <div>
    <component :is="'h' + this.type">
      <span style="cursor: pointer; float: right;" @click="changeType">h{{ type }}</span>
      <content-editable-input
              style="display: inline-block; width: 50%;"
            :ref="'heading-' + id"
            class="block-paragraph"
            v-model="content"
            @onEnter="onEnter"
            @onCtrlEnter="onCtrlEnter"
            @onArrowUp="onArrowUp"
            @onArrowDown="onArrowDown"
            @onBlur="onBlur"
            @onEmptyAndBackspace="onEmptyAndBackspace"
            @onEmptyAndDelete="onEmptyAndDelete"
    /></component>
  </div>
</template>

<script>
import ContentEditableInput from "@/components/ContentEditableInput"

export default {
  props: ['data', 'editor', 'id', 'index'],
  components: {ContentEditableInput},
  data() {
    return {
      content: this.data.text,
      type: this.data.type,
    }
  },
  computed: {},
  mounted() {
    console.log(this.editor)
  },
  watch: {
    content(val) {
      this.editor.changeBlock(val, this.index, this.id)
    },
  },
  methods: {
    changeType() {
      if (this.type >= 6) {
        this.type = 1
      } else {
        this.type++
      }

      this.editor.emitOnChange()
    },
    focus() {
      this.$refs['heading-' + this.id].$el.focus()
    },
    getBlockType() {
      return 'heading'
    },
    getBlockData() {
      return {
        text: this.content,
        type: this.type,
      }
    },
    onEnter(text) {
      this.editor.addBlock({
        type: 'paragraph',
        data: {
          text: '',
        },
      }, this.index)
    },
    onCtrlEnter(text) {
      this.editor.emitOnChange()
    },
    onArrowUp() {
      this.editor.focusPrev(this.index)
    },
    onArrowDown() {
      this.editor.focusNext(this.index)
    },
    onBlur() {
      this.editor.emitOnChange()
    },
    onEmptyAndBackspace(text) {
      this.editor.deleteBlock(this.index, this.id)
    },
    onEmptyAndDelete(text) {
      this.editor.deleteBlock(this.index, this.id, 'next')
    },
  },
}
</script>

<style scoped>
.block-paragraph {
  margin-bottom: 10px;
  padding-left: 5px;
}
</style>

<template>
  <sms-card header-title="Sleep log">
    <div v-if="!day.sleep_log && !saved">
      <select v-model="sleepLog">
        <option value=""></option>
        <option value="bad">Плохо</option>
        <option value="so-so">Так себе</option>
        <option value="good">Хорошо</option>
        <option value="excellent">Отлично</option>
      </select>

      <a href="#" @click.prevent="save">save</a>
    </div>

    <div v-if="day || tempVal">
      {{ day.sleep_log || tempVal }}
    </div>
  </sms-card>
</template>

<script>
import axios from "@/plugins/axios"
import SmsCard from "@/views/components/Card";

export default {
  props: ['day'],
  components: {SmsCard},
  data() {
    return {
      sleepLog: null,
      saved: false,
      tempVal: null,
    }
  },
  computed: {},
  mounted() {
  },
  methods: {
    save() {
      axios.post('/health/log/sleep', {date: this.day.date, log: this.sleepLog})
        .then(response => {
          this.saved = true
          this.tempVal = this.sleepLog
        })
    },
  },
}
</script>

<template>
  <div>
    <vue-slider v-model="delay"
                :interval="1"
                :min="0"
                :max="max"
                :tooltip="'none'"
                style="cursor: pointer;"
                :lazy="true"
                :marks="marks"
                :duration="0"
                :included="true"
    >
      <template v-slot:step="{ label, active }">
        <div :class="['custom-step', { active }]"></div>
      </template>
    </vue-slider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  components: {VueSlider},
  computed: {
    max() {
      return 10
    },
    marks() {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    delay: {
      set(value) {
        this.$store.commit('guitar/setDelay', value)
      },
      get() {
        return this.$store.state.guitar.delay
      },
    }
  },
}
</script>

<template>
  <page title="Журнал веса">
    <div class="row">
      <div class="col-lg-6">
        <sms-page id="weight-page" />
      </div>
      <div class="col-lg-6">
        <table class="table table-sm">
          <tr v-for="(log,index) in logs" :key="index">
            <td style="width: 150px;">
              {{ log.date }}
            </td>
            <td>
              {{ log.log }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </page>
</template>

<script>
import Page from "@/components/Page"
import axios from "@/plugins/axios";
import SmsPage from "@/components/SmsPage";

export default {
  components: {SmsPage, Page},
  data() {
    return {
      logs: [],
    }
  },
  mounted() {
    this.getWeightLogs()
  },
  methods: {
    getWeightLogs() {
      axios.get('/health/log/weight').then(response => {
        this.logs = response.data.data

        console.log('logs', this.logs)
      })
    },
  },
}
</script>

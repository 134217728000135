<template>
  <div class="row">
    <div class="col-lg-2" style="text-align: center; font-size: 20px; vertical-align: middle">
      {{ speedValue }}
    </div>
    <div class="col-lg-10">
      <vue-slider v-model="speedValue"
                  @change="changeSpeed"
                  :interval="0.01"
                  :min="0.5"
                  :max="1"
                  :tooltip="'none'"
                  style="cursor: pointer;"
                  :lazy="true"
                  :marks="marks"
                  :duration="0"
                  :included="false"
      >
        <template v-slot:step="{ label, active }">
          <div :class="['custom-step', { active }]"></div>
        </template>
      </vue-slider>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  props: {
    segmentId: {
      type: String,
    },
    speed: {
      type: Number,
      default: 0.5,
    }
  },
  components: {VueSlider},
  data() {
    return {
      speedValue: this.speed,
    }
  },
  computed: {
    marks() {
      return [0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
    }
  },
  mounted() {},
  destroyed() {},
  methods: {
    changeSpeed(value) {
      this.$store.dispatch('guitar/setSegmentSpeed', {
        segmentId: this.segmentId,
        speed: value,
      })

      this.$store.dispatch('guitar/speedRateSound', value)
    },
  },
}
</script>

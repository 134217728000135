export default [
  {
    path: '/crud/:module',
    component: () => import('@/views/crud/index'),

    children: [
      {
        path: '',
        name: 'crud-list',
        component: () => import('@/views/crud/list'),
      },
      {
        path: 'view/:view',
        name: 'crud-view',
        component: () => import('@/views/crud/list'),
      },
      {
        path: ':id',
        name: 'crud-item',
        component: () => import('@/views/crud/item'),
      }
    ],
  },
]

<template>
  <sms-card style="text-align: center;">
    <sms-date-picker
      @input="onDatePicked"
      :value="day.date"
    />
  </sms-card>
</template>

<script>
import axios from "@/plugins/axios"
import SmsDatePicker from "@/components/DatePicker";
import SmsCard from "@/views/components/Card";

export default {
  props: ['day'],
  components: {SmsCard, SmsDatePicker},
  data() {
    return {
      days: [],
      next: null,
      prev: null,
    }
  },
  computed: {
    links() {
      return this.days.map(day => {
        return {
          label: this.getDayOfMonth(day.date),
          date: day.date,
        }
      })
    },
  },
  mounted() {
    this.loadDaysNav()
  },
  methods: {
    loadDaysNav() {
      const segments = this.day.date.split('-')

      axios.get('/day-overview/navigation/' + segments[0] + '/' + segments[1])
        .then(response => {
          this.days = response.data.days
          this.next = response.data.next
          this.prev = response.data.prev
        })
    },
    getDayOfMonth(date) {
      const segments = date.split('-')

      return segments[2]
    },
    goToDate(link) {
      this.$router.push({name: 'days-overview', params: {day: link.date}})
    },
    isLinkActive(link) {
      return link.date === this.day.date
    },
    goNext() {
      this.goToDate({
        date: this.next,
      })
    },
    goPrev() {
      this.goToDate({
        date: this.prev,
      })
    },
    onDatePicked(date) {
      if (date) {
        date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()

        this.goToDate({
          date: date
        })
      }
    },
  },
}
</script>

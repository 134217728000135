<template>
  <div class="page">
    <div class="page-main">
      <sms-header v-if="loggedIn" />
      <sms-left-sidebar v-if="loggedIn" />

      <div class="app-content">
        <div class="section">
          <router-view></router-view>
        </div>
      </div>
    </div>

    <sms-right-sidebar v-if="loggedIn" />
  </div>
</template>

<script>
import SmsHeader from "@/views/partials/Header"
import SmsLeftSidebar from "@/views/partials/LeftSidebar"
import SmsRightSidebar from "@/views/partials/RightSidebar"
import auth from "@/plugins/auth";

export default {
  components: {SmsRightSidebar, SmsLeftSidebar, SmsHeader},
  computed: {
    loggedIn() {
      return auth.loggedIn()
    }
  }
}
</script>

<style>

</style>

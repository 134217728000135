<template>
    <div style="font-size: 30px; font-weight: bold; text-align: left">
        Notes: {{ allNotesCount }} /
        Level: {{ maxLevel }}
    </div>
</template>

<script>
export default {
    mounted() {
        this.updateAllNotesCount()
    },
    computed: {
        allNotesCount() {
            return this.$store.state.kb.allNotesCount
        },
        maxLevel() {
            return this.$store.state.kb.notesChildsMaxLevel
        },
    },
    methods: {
        updateAllNotesCount() {
            this.$store.dispatch('kb/updateAllNotesCount')
        },
    }
}
</script>

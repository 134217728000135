<template>
  <div class="divider">
    <hr />
  </div>
</template>

<script>
export default {
  props: ['data', 'editor', 'id', 'index'],
  methods: {
    focus() {},
    getBlockType() {
      return 'divider'
    },
    getBlockData() {
      return {}
    },
  }
}
</script>

<style scoped>
.divider {
  margin-top: 10px;
  margin-bottom: 15px;
  padding-top: 1px;
}
</style>

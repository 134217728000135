<template>
  <div id="kb-app" v-hotkey="keymap">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">

        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <search-note />
          <create-update-note />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-9">
          <notes-search-result />
        </div>
        <div class="col-lg-3">
          <all-notes-count />
          <sticky-notes />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateUpdateNote from '@/pages/Kb/CreateUpdateNote'
import NotesSearchResult from '@/pages/Kb/NotesSearchResult'
import SearchNote from '@/pages/Kb/SearchNote'
import StickyNotes from '@/pages/Kb/StickyNotes'
import AllNotesCount from '@/pages/Kb/AllNotesCount'
import { focus } from 'vue-focus'

export default {
  directives: { focus: focus },
  components: {
    AllNotesCount,
    CreateUpdateNote,
    SearchNote,
    NotesSearchResult,
    StickyNotes,
  },
  computed: {
    maxLevel: {
      get() {
        return this.$store.state.kb.notesChildsMaxLevel
      },
      set(val) {
        this.$store.commit('kb/setNotesChildsMaxLevel', val)
      }
    },
    currentNoteRelations() {
      return this.notesTree.length ? this.notesTree[0].title : ''
    },
    keymap() {
      return {
        // 'right': {
        //     keyup: this.test,
        // },
        'left': {
          keyup: this.goToRoot,
        },
        'up': {
          keyup: this.descrementMaxLevel,
        },
        'down': {
          keyup: this.incrementMaxLevel,
        },
        // 'alt+t': {
        //     keyup: this.activateSearchInputFocus,
        // },
        // 'alt+o': {
        //     keyup: this.activateEditingInputFocus,
        // },
        // 'esc': {
        //     keyup: this.unfocusInputs,
        // }
      }
    },
  },
  methods: {
    // changeInputFocus(input) {
    //     console.log('change input focus', input)
    //
    //     this.showInputFocus = input
    //     this.inputFocus = input
    // },
    // activateSearchInputFocus() {
    //     this.changeInputFocus('search')
    // },
    // activateEditingInputFocus() {
    //     this.changeInputFocus('editing')
    // },
    // unfocusInputs() {
    //     this.inputFocus = null
    // },
    incrementMaxLevel() {
      this.maxLevel++
    },
    descrementMaxLevel() {
      this.maxLevel--
    },
    goToRoot() {
      let root = this.notesTree[0].roots[0]

      if(root) {
        this.searchNotes(root.title)
      }
    },
  }
}
</script>

<style scoped>
#kb-app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 10px;
}
.notes-container {
  margin-top: 20px;
}
</style>

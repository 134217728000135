import TasksPage from "@/views/pages/Tasks/TasksPage"
import TaskView from "@/views/pages/Tasks/TaskView"
import TasksProjectsList from "@/views/pages/Tasks/TasksProjectsList"
import TaskProjectView from "@/views/pages/Tasks/TaskProjectView"

export default [
  {
    path: '/tasks',
    component: TasksPage,
    name: 'tasks',
    children: [
      {
        path: 'inbox',
        component: TasksPage,
        name: 'tasks-inbox',
      },
      {
        path: 'tomorrow',
        component: TasksPage,
        name: 'tasks-tomorrow',
      },
      {
        path: 'week',
        component: TasksPage,
        name: 'tasks-week',
      },
      {
        path: 'no-due-date',
        component: TasksPage,
        name: 'tasks-no-due-date',
      },
      {
        path: 'all',
        component: TasksPage,
        name: 'tasks-all',
      },
      {
        path: 'deleted',
        component: TasksPage,
        name: 'tasks-deleted',
      },
    ],
  },
  {
    path: '/task/projects',
    component: TasksProjectsList,
    name: 'tasks-projects',
  },
  {
    path: '/task/projects/:id',
    component: TaskProjectView,
    name: 'task-project',
  },
  {
    path: '/task/:id',
    component: TaskView,
    name: 'task-view',
  },
]

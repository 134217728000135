<template>
  <div>
    <div
        v-for="dayTask in dayTasks.sort(compareTasks)"
        :key="dayTask.task.id"
        :class="{
        'day-task-done': dayTask.status === 'done',
        'day-task-not-done': dayTask.status === 'not_done',
        'day-task-important': dayTask.task.is_important,
        'day-task-active': activeDayTask && activeDayTask.id === dayTask.id,
      }"
        class="d-flex p-3 day-overview-task-item"
        @dblclick="toggleActiveDayTask(dayTask)"
    >
      <label class="custom-control custom-checkbox mb-0">
        <input
                v-if="dayTask.status !== 'not_done'"
                :checked="dayTask.status === 'done'"
                @change="taskDone(dayTask)"
                type="checkbox"
                class="custom-control-input"
                name="example-checkbox1"
                value="option1"
        >

        <span class="custom-control-label">
          <span
            v-if="dayTask.status === 'in_row'"
            @click.prevent="taskNotDone(dayTask)"
            class="mr-2"
          >
          <sms-icon
                  name="fa fa-frown-o"
                  color="danger"
                  style="cursor: pointer;"
                  title="Не сделана"
          />️
        </span>

          <router-link :to="{name: 'task-view', params: {id: dayTask.task_id}}">{{ dayTask.task.name }}</router-link>
        </span>

        <sms-icon v-if="dayTask.task.text_blocks !== '[]'" name="fa fa-comments" color="info" class="ml-2" title="Has comment" />
        <a @click="openProject(dayTask.task.project_id)" style="cursor: pointer">
          <sms-icon  v-if="dayTask.task.project_id" name="fa fa-folder-open-o" color="danger" class="ml-2" title="Project" />
        </a>
      </label>
      <span class="ml-auto">

        <sms-icon v-if="dayTask.task.recurring" name="repeat" color="info" class="ml-2" title="recurring" />
        <sms-icon v-if="dayTask.task.notify_at_time" name="fa fa-bell" color="orange" class="ml-2"
                  :title="'Notify at ' + dayTask.task.notify_at_time"
        />
        <sms-icon v-if="dayTask.task.is_chore" name="mdi mdi-animation" color="green" class="ml-2" title="chore" />
        <sms-icon v-if="dayTask.task.is_outdoor" name="mdi mdi-car" color="warning" class="ml-2" title="outdoor" />
      </span>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import SmsIcon from "@/views/components/Icon";

export default {
  props: ['dayTasks'],
  components: {SmsIcon},
  computed: {
    activeDayTask() {
      return this.$store.state.dayOverview.activeDayTask
    }
  },
  methods: {
    taskDone(dayTask) {
      if (dayTask.status === 'done') {
        return
      }

      axios.put('/day-overview/task/' + dayTask.id + '/status', {status: 'done'})
        .then(() => {
          this.$store.dispatch('dayOverview/updateOverviewTasks')
        })
    },
    taskNotDone(dayTask) {
      if (!confirm('Task not done?')) {
        return
      }

      axios.put('/day-overview/task/' + dayTask.id + '/status', {status: 'not_done'})
        .then(() => {
          this.$store.dispatch('dayOverview/updateOverviewTasks')
        })
    },
    compareTasks(a, b) {
      if (a.status === 'done' && b.task.is_done !== 'done') {
        return 1
      }

      if (a.status !== 'done' && b.task.is_done === 'done') {
        return -1
      }

      if (a.task.is_important && !b.task.is_important) {
        return -1
      }

      if (!a.task.is_important && b.task.is_important) {
        return 1
      }

      return 0
    },
    openProject(projectId) {
      this.$router.push({name: 'task-project', params: {id: projectId}})
    },
    toggleActiveDayTask(dayTask) {
      let activeDayTask = dayTask

      if (this.activeDayTask && this.activeDayTask.id === dayTask.id) {
        activeDayTask = null
      }

      console.log('test', activeDayTask)

      this.$store.commit('dayOverview/setActiveDayTask', activeDayTask)
    }
  }
}
</script>

<style scoped>
.day-task-active {
  background: #464561;
}
</style>

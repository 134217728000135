<template>
  <page title="Журнал сна">
    <div class="row">
      <div class="col-lg-6">
        <sms-page id="sleep-page" />
      </div>
      <div class="col-lg-6">
        <div style="text-align: center;">
          <sms-calendar
                  :rows="isMobile ? 3 : 2"
                  :columns="isMobile ? 1 : 3"
                  :attributes="attributes"
          ></sms-calendar>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from "@/components/Page"
import SmsCalendar from "@/components/SmsCalendar"
import axios from "@/plugins/axios"
import SmsPage from "@/components/SmsPage"

export default {
  components: {SmsPage, SmsCalendar, Page},
  data() {
    return {
      logs: [],
    }
  },
  computed: {
    isMobile() {
      return localStorage.getItem('is_mobile') === '1'
    },
    attributes() {
      return this.logs
              .filter(log => log.log !== null)
              .map(log => {
                return {
                  highlight: this.logColor(log.log),
                  dates: new Date(log.date),
                  excludeDates: null,
                  order: 0
                }
              })
    },
  },
  mounted() {
    this.getSleepLogs()
  },
  methods: {
    getSleepLogs() {
      axios.get('/health/log/sleep').then(response => {
        this.logs = response.data.data

        console.log('logs', this.logs)
      })
    },
    logColor(log) {
      switch (log) {
        case 'bad':
          return 'red'

        case 'so-so':
          return 'orange'

        case 'good':
          return 'teal'

        case 'excellent':
          return 'green'

        default:
          return 'pink'
      }
    }
  },
}
</script>

import axios from "@/plugins/axios"

export default {
  namespaced: true,
  state: {
    list: [],
    currentPage: {},
    blocks: [],
    isSaving: false,
    availableBlocks: {
      paragraph: {
        name: 'paragraph',
        text: '',
      },
      'todo-list': {
        name: 'todo-list',
        items: [{text: '', done: false}]
      }
    }
  },
  mutations: {
    setIsSaving(state, isSaving) {
      state.isSaving = isSaving
    },
    setPages(state, data) {
      state.list = data
    },
    setCurrentPage(state, page) {
      state.currentPage = page
    },
    setBlocks(state, blocks) {
      state.blocks = blocks
    },
  },
  actions: {
    createPage({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('/page', data).then(response => {
          resolve(response.data.data)
        })
      })
    },
    loadPages({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/page').then(response => {
          commit('setPages', response.data.data)
          resolve()
        })
      })
    },
    loadPage({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.get('/page/' + data.id).then(response => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deletePage({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.delete('/page/' + data.page.id).then(response => {
          resolve()
        })
      })
    },
    savePageTitle({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('/page/' + data.id + '/title', {title: data.title}).then(response => {
          resolve(response.data)
        })
      })
    },
    savePageBlocks({commit}, data) {
      return new Promise((resolve, reject) => {
        commit('setIsSaving', true)

        axios.post('/page/' + data.id + '/blocks', {blocks: data.blocks})
          .then(response => {
            commit('setIsSaving', false)
            resolve(response.data)
          })
      })
    },
  }
}

<template>
  <div class="page row">
    <div class="col-lg-12">
      <template v-if="loaded">
        <div class="row">
          <div class="col-lg-6">
            <h1 v-show="title">{{ title }}</h1>
          </div>
          <div class="col-lg-6">
            <slot name="header-tools">
            </slot>
          </div>
        </div>

        <slot></slot>
      </template>

      <template v-else>
        Loading page...
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    loaded: {
      type: Boolean,
      default: true,
    }
  }
}
</script>

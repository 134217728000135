<template>
  <div>
    <div class="page-header">
      <ol v-if="breadcrumbs.length > 0" class="breadcrumb">
        <li v-for="(item,index) in breadcrumbs" :key="index" class="breadcrumb-item">

          <router-link v-if="item.hasOwnProperty('route')" :to="item.route">
            <i class="fe fe-home mr-1"></i> {{ item.label }}
          </router-link>

          <template v-else>
            {{ item.label }}
          </template>

        </li>
      </ol>

      <h3 v-else>{{ title ? title : '[Untitled]'}}</h3>

      <div class="ml-auto">
        <slot name="tools">

  <!--          <a href="#" class="btn btn-primary btn-icon btn-sm text-white mr-2">-->
  <!--            <span>-->
  <!--                <i class="fe fe-plus"></i>-->
  <!--            </span> Add Account-->
  <!--          </a>-->
  <!--          <a href="#" class="btn btn-secondary btn-icon  btn-sm text-white">-->
  <!--            <span>-->
  <!--                <i class="fe fe-log-in"></i>-->
  <!--            </span> Export-->
  <!--          </a>-->

        </slot>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'sms-content',
  props: {
    breadcrumbs: {
      type: Array,
      default() {
        return []
      },
    },
    title: {
      type: String,
    }
  },
  methods: {
    goTo(route) {
      this.$router.push(route)
    }
  },
}
</script>

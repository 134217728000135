<template>
  <sms-content title="Контакты">
    <sms-grid-row>
      <sms-card>
        <ul>
          <li v-for="contact in contacts" :key="contact.id">
            <router-link :to="{name: 'contact-view', params: {id: contact.id}}">{{ contact.title }}</router-link>
          </li>
        </ul>

        <hr />

        <p>
          <input type="text" placeholder="name" v-model="newContactName" />
          <a class="btn btn-primary btn-sm" @click="createContact">Create</a>
        </p>
      </sms-card>
    </sms-grid-row>
  </sms-content>
</template>

<script>
import axios from "@/plugins/axios"
import SmsContent from "@/views/partials/Content";
import SmsGridRow from "@/views/components/GridRow";
import SmsCard from "@/views/components/Card";

export default {
  components: {SmsCard, SmsGridRow, SmsContent},
  data() {
    return {
      contacts: [],
      newContactName: '',
      contactRelations: [],
    }
  },
  computed: {},
  mounted() {
    this.loadContacts()
    this.loadContactRelations()
  },
  destroyed() {
  },
  methods: {
    loadContacts() {
      axios.post('/note/search', {query: 'я > контакт', level: 1}).then(response => {
        this.contacts = response.data.notes_tree
      })
    },
    createContact() {
      axios.post('/note/create', {
        title: [this.newContactName],
        type: 'entity',
        relations: this.contactRelations,
        subtype: 'my_contact',
      }).then(response => {
        this.contacts.push(response.data)
      })

      this.newContactName = ''
    },
    loadContactRelations() {
      axios.post('/note/search', {query: 'я', level: 1}).then(response => {
        this.contactRelations.push(response.data.notes_tree[0].id)
      })

      axios.post('/note/search', {query: 'контакт', level: 1}).then(response => {
        this.contactRelations.push(response.data.notes_tree[0].id)
      })
    },
  },
}
</script>

<template>
  <div class="row">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'sms-grid-row',
}
</script>

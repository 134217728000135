<template>
  <sms-content title="PC">
    <sms-grid-row>
      <sms-card>
        <sms-page id="pc"></sms-page>
      </sms-card>
    </sms-grid-row>
  </sms-content>
</template>

<script>
import markdownParser from '@/plugins/markdown-parser'
import SmsPage from "@/components/SmsPage";
import SmsContent from "@/views/partials/Content";
import SmsGridRow from "@/views/components/GridRow";
import SmsCard from "@/views/components/Card";

export default {
  components: {SmsCard, SmsGridRow, SmsContent, SmsPage}
}
</script>

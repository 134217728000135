export default [
  {
    'label': 'Dashboard',
    'route': {name: 'dashboard'},
  },
  {
    'label': 'Content editable',
    'route': {name: 'content-editable-dev'},
  },
  {
    'label': 'Overview',
    'subItems': [
      {
        'label': 'Today',
        'route': {name: 'days-overview', params: {day: 'today'}},
      },
      {
        'label': 'Завтра',
        'route': {name: 'days-overview', params: {day: 'tomorrow'}},
      },
      {
        'label': 'Недели',
        'route': {name: 'weeks-list-overview'},
      },
      {
        'label': 'Месяцы',
        'route': {name: 'months-list-overview'},
      },
      {
        'label': 'Годы',
        'route': {name: 'years-list-overview'},
      },
    ],
  },
  {
    'label': 'Менеджеры',
    'subItems': [
      {
        'label': 'Tasks',
        'route': {name: 'tasks'},
      },
      {
        'label': 'Tasks projects',
        'route': {name: 'tasks-projects'},
      },
      {
        'label': 'Knowledge base',
        'route': {name: 'kb'},
      },
      {
        'label': 'Issues',
        'route': {name: 'issues-list'},
      },
      {
        'label': 'Training',
        'route': {name: 'training'},
      },
    ],
  },
  {
    'label': 'Справочники',
    'subItems': [
      {
        'label': 'Контакты',
        'route': {name: 'contacts-list'},
      },
      {
        'label': 'Новые Контакты',
        'route': {name: 'crud-list', params: {module: 'contacts'}},
      },
      {
        'label': 'Организации',
        'route': {name: 'orgs-list'},
      },
      {
        'label': 'House',
        'route': {name: 'house'},
      },
      {
        'label': 'Car',
        'route': {name: 'car'},
      },
      {
        'label': 'PC',
        'route': {name: 'pc'},
      },
    ],
  },
  {
    'label': 'Коллекции',
    'subItems': [
      {
        'label': 'Games',
        'route': {name: 'games'},
      },
      {
        'label': 'Songs',
        'route': {name: 'songs'},
      },
      {
        'label': 'Guitar songs',
        'route': {name: 'guitar'},
      },
      {
        'label': 'Books',
        'route': {name: 'books'},
      },
      {
        'label': 'Kate movies',
        'route': {name: 'kate-movies'},
      },
    ],
  },
  {
    'label': 'Журналы',
    'subItems': [
      {
        'label': 'Health',
        'route': {name: 'health'},
      },
      {
        'label': 'Сон',
        'route': {name: 'logs-sleep'},
      },
      {
        'label': 'Вес',
        'route': {name: 'logs-weight'},
      },
    ],
  },
]

<template>
  <div class="table-responsive">
    <table class="table table-inbox table-hover text-nowrap">
      <tbody>
        <tr v-for="(task,index) in tasks" :key="index" class="unread">
          <td class="view-message ">
            <router-link :to="{name: 'task-view', params: {id: task.id}}"
                         :class="{'task-done': task.is_done}"
            >{{ task.name }}</router-link>
          </td>
          <td class="view-message  inbox-small-cells">
            <sms-icon v-if="task.recurring" name="repeat" color="info" class="ml-2" title="recurring" />
            <sms-icon v-if="task.notify_at_time" name="fa fa-bell" color="orange" class="ml-2"
                      :title="'Notify at ' + task.notify_at_time"
            />
            <sms-icon v-if="task.is_chore" name="fa fa-cutlery" color="green" class="ml-2" title="chore" />
          </td>
          <td class="view-message  text-right">{{ task.due_date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SmsIcon from "@/views/components/Icon";
export default {
  props: ['tasks'],
  components: {SmsIcon},
  data() {
    return {
      headers: [
        {name: 'is_done', label: ''},
        {name: 'name', label: 'Name'},
        {name: 'due_date', label: 'Due date'},
      ]
    }
  },
}
</script>

<style scoped>
.task-done {
  text-decoration: line-through;
}
</style>

<template>
  <sms-card :body-padding="0">
    <ul>
      <li v-for="(track, id) in tracks" :key="id"
          :class="{'list-group-item-success': isActiveTrack(id), 'track-item': true}"
          @click.prevent="setCurrentTrack(id)"
          style="cursor: pointer"
          class="p-2"
      >
        <div class="row align-items-center">
          <div class="col">
            <span >
              {{ track.name }}
            </span>
          </div>
          <div class="col-auto">
            <a class="badge" @click.prevent.stop="deleteTrack(id)" href="#"><sms-icon name="fa fa-trash"></sms-icon></a>
          </div>
        </div>
      </li>
    </ul>

    <add-track />
  </sms-card>
</template>

<script>
import SmsCard from "@/views/components/Card";
import SmsIcon from "@/views/components/Icon";
import AddTrack from "./AddTrack";
import PlayerTimeline from "@/views/pages/guitar/song/tracks/PlayerTimeline";

export default {
  components: {SmsIcon, SmsCard, AddTrack},
  computed: {
    tracks() {
      return this.$store.state.guitar.song.tracks
    },
  },
  methods: {
    deleteTrack(id) {
      if(!confirm('Are you sure?')) {
        return
      }

      this.$store.dispatch('guitar/deleteTrack', {
        track_id: id,
      })
    },
    setCurrentTrack(id) {
      this.$store.dispatch('guitar/loadTrack', id)
    },
    isActiveTrack(trackId) {
      return this.$store.state.guitar.currentTrack && trackId === this.$store.state.guitar.currentTrack.id
    },
  },
}
</script>

<style scoped>
.track-item.active span {
  color: red;
}
</style>

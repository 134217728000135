import axios from "@/plugins/axios"

export default {
  namespaced: true,
  state: {
    day: null,
    dayTasks: [],
    filters: {
      done: 'any',
      chore: 'any',
      importance: 'any',
      outdoor: 'any'
    },
    activeDayTask: null
  },
  mutations: {
    setDayTasks(state, dayTasks) {
      state.dayTasks = dayTasks
    },
    setDay(state, day) {
      state.day = day
    },
    setFilters(state, filters) {
      state.filters = filters
    },
    resetFilters(state) {
      state.filters = {
        done: 'any',
        chore: 'any',
        importance: 'any',
        outdoor: 'any'
      }
    },
    setActiveDayTask(state, task) {
      state.activeDayTask = task

      localStorage.setItem('active_day_task', task ? task.id : null)
    }
  },
  actions: {
    loadDayOverviewTasks({commit}, date) {
      return new Promise((resolve, reject) => {
        axios.get('/day-overview/' + date + '/task').then(response => {
          commit('setDayTasks', response.data.data)

          resolve(response.data.data)
        })
      })
    },
    updateOverviewTasks({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get('/day-overview/' + state.day.date + '/task').then(response => {
          commit('setDayTasks', response.data.data)

          resolve(response.data.data)
        })
      })
    }
  }
}

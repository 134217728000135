import DayOverviewPage from "@/views/pages/day-overview/index"
import WeeksListOverviewPage from "@/views/pages/WeeksListOverviewPage"
import MonthsListOverviewPage from "@/views/pages/MonthsListOverviewPage"
import YearsListOverviewPage from "@/views/pages/YearsListOverviewPage"

export default [
  {
    path: '/days-overview/:day',
    component: DayOverviewPage,
    name: 'days-overview',
  },
  {
    path: '/weeks-list-overview',
    component: WeeksListOverviewPage,
    name: 'weeks-list-overview',
  },
  {
    path: '/months-list-overview',
    component: MonthsListOverviewPage,
    name: 'months-list-overview',
  },
  {
    path: '/years-list-overview',
    component: YearsListOverviewPage,
    name: 'years-list-overview',
  },
]

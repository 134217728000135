<template>
    <div class="position-relative" style="width: 100%;">
        <span v-show="note.id"
              class="position-absolute"
              style="left: -20px; top: 10px; cursor: pointer"
              @click="setCreateMode"
        >
            <b-icon-pen />
        </span>

        <input
            @keyup.enter="createNote"
            v-model="note.editingTitle"
            type="text"
            class="form-control"
            placeholder="create note"
        />

        <div style="text-align: left;">
            <span v-for="item in note.relations" :key="item.id">
                <b-badge variant="primary" @click="removeNewNoteRelation(item)" class="mr-2" style="cursor: pointer;">{{ item.title }}</b-badge>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {
        note() {
            return this.$store.state.kb.editingNote
        },
    },
    mounted() {
        this.$store.commit('kb/clearEditingNote')
    },
    methods: {
        createNote() {
            console.log('create note', this.note)

            const noteType = this.detectNoteType(this.note.editingTitle)
            const noteSubtype = this.detectNoteSubtype(this.note.title)

            const note = {
                id: this.note.id,
                title: this.removeSpecialSigns(this.note.editingTitle).split('|').map(x => x.trim()),
                type: noteType,
                subtype: '',
                relations: this.note.relations.map(x => x.id),
            }

            let mode = this.note.id ? 'update' : 'create'

            this.$store.dispatch('kb/saveNote', note).then(note => {
                console.log('saving ok', note)

                if(mode === 'create') {
                    this.$store.commit('kb/addStickyNote', note)
                }

                // this.$store.commit('updateEditingNote', {
                //     id: null,
                //     title: '',
                //     relations: this.editingNote.relations
                // })
            }).catch(error => {
                console.log('creating error', error, error.response)

                if(error.response && error.response.data) {
                    alert(JSON.stringify(error.response.data.errors))
                } else {
                    alert('error')
                }
            })
        },
        setCreateMode() {
            let note = this.$store.state.kb.editingNote
            note.id = null
            note.title = ''
            note.editingTitle = ''

            this.$store.commit('kb/updateEditingNote', note)
            this.$store.commit('kb/clearNewNoteRelations')
        },
        detectNoteType(title) {
            return title.substr(0, 1) === '#' ? 'entity' : 'fact';
        },
        detectNoteSubtype(title) {

        },
        removeSpecialSigns(title) {
            return (title.substr(0, 1) === '#' ? title.substr(1) : title).trim()
        },
        removeNewNoteRelation(note) {
            this.$store.commit('kb/removeNewNoteRelation', note)
        },
    }
}
</script>

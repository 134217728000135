<template>
  <div v-if="item">
    <h1>{{ item.title }}</h1>

    <h3>Relations</h3>

    <ul>
      <li v-for="relation in item.relations" :key="relation.id">
        {{ relation.title }}
      </li>
    </ul>

    <hr />

    <a class="btn btn-danger btn-sm" @click="deleteItem">delete</a>
  </div>
</template>

<script>
import axios from "../plugins/axios";

export default {
  components: {},
  data() {
    return {
      item: null,
    }
  },
  computed: {},
  mounted() {
    this.load()
  },
  methods: {
    load() {
      axios.get('/note/' + this.$route.params.id).then(response => {
        this.item = response.data
      })
    },
    deleteItem() {
      if (!confirm('Delete?')) {
        return
      }

      axios.delete('/note/delete/' + this.item.id).then(response => {
        this.$router.push({name: 'orgs-list'})
      })
    },
  },
}
</script>

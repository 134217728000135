<template>
  <vc-date-picker
    :value="value"
    @input="onInput"
    :first-day-of-week="2"
    is-dark
    :min-date="minDate"
    :attributes="attributes"
  />
</template>

<script>
export default {
  name: 'sms-date-picker',
  props: ['value', 'minDate'],
  computed: {
    attributes() {
      return [
        {
          key: 'today',
          highlight: {
            color: 'yellow',
            fillMode: 'outline',
            contentClass: 'italic',
          },
          dates: new Date(),
          order: 0
        }
      ]
    },
  },
  methods: {
    onInput(date) {
      this.$emit('input', date)
    }
  },
}
</script>

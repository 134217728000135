<template>
  <div class="header app-header sidebar-opened">
    <div class="container-fluid">
      <div class="d-flex header-nav">
        <a href="#" data-toggle="sidebar" class="nav-link icon toggle">
          <i class="fe fe-align-justify"></i>
        </a>

        <device-type-setter />

        <a @click.prevent="createTask" href="#" class="nav-link icon">
          <sms-icon name="task" />
        </a>

        <div class="d-flex order-lg-2 ml-auto header-right-icons header-search-icon">
          <div class="dropdown">
            <a @click="logOut" class="nav-link icon nav-link-bg">
              <sms-icon name="fa fa-sign-out" />
            </a>
          </div><!-- FULL-SCREEN -->
<!--          <div class="dropdown  notifications">-->
<!--            <a class="nav-link icon" data-toggle="dropdown">-->
<!--              <i class="fe fe-bell"></i>-->
<!--              <span class="pulse bg-danger"></span>-->
<!--            </a>-->
<!--            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">-->
<!--              <a href="#" class="dropdown-item mt-2 d-flex pb-3">-->
<!--                <div class="notifyimg bg-info">-->
<!--                  <i class="fa fa-thumbs-o-up"></i>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <h6 class="mb-1">Someone likes our posts.</h6>-->
<!--                  <div class="small text-muted">3 hours ago</div>-->
<!--                </div>-->
<!--              </a>-->
<!--              <a href="#" class="dropdown-item d-flex pb-3">-->
<!--                <div class="notifyimg bg-warning">-->
<!--                  <i class="fa fa-commenting-o"></i>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <h6 class="mb-1"> 3 New Comments</h6>-->
<!--                  <div class="small text-muted">5  hour ago</div>-->
<!--                </div>-->
<!--              </a>-->
<!--              <a href="#" class="dropdown-item d-flex pb-3">-->
<!--                <div class="notifyimg bg-danger">-->
<!--                  <i class="fa fa-cogs"></i>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <h6 class="mb-1"> Server Rebooted.</h6>-->
<!--                  <div class="small text-muted">45 mintues ago</div>-->
<!--                </div>-->
<!--              </a>-->
<!--              <div class="border-top">-->
<!--                <a href="#" class="dropdown-item text-center">View all Notification</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>&lt;!&ndash; NOTIFICATIONS &ndash;&gt;-->
<!--          <div class="dropdown  message">-->
<!--            <a class="nav-link icon text-center" data-toggle="dropdown">-->
<!--              <i class="fe fe-mail"></i>-->
<!--              <span class=" nav-unread badge badge-warning badge-pill">6</span>-->
<!--            </a>-->
<!--            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">-->
<!--              <a href="#" class="dropdown-item d-flex mt-2 pb-3">-->
<!--                <div class="avatar avatar-md brround mr-3 d-block cover-image" data-image-src="assets/images/users/male/4.jpg">-->
<!--                  <span class="avatar-status bg-green"></span>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <h6 class="mb-1">Lucas Walsh</h6>-->
<!--                  <p class="mb-0 fs-13 ">Hey! there I' am available</p>-->
<!--                  <div class="small text-muted">3 hours ago</div>-->
<!--                </div>-->
<!--              </a>-->
<!--              <a href="#" class="dropdown-item d-flex pb-3">-->
<!--                <div class="avatar avatar-md brround mr-3 d-block cover-image" data-image-src="assets/images/users/female/1.jpg">-->
<!--                  <span class="avatar-status bg-red"></span>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <h6 class="mb-1">Rebecca Short</h6>-->
<!--                  <p class="mb-0 fs-13 ">New product Launching</p>-->
<!--                  <div class="small text-muted">5  hour ago</div>-->
<!--                </div>-->
<!--              </a>-->
<!--              <a href="#" class="dropdown-item d-flex pb-3">-->
<!--                <div class="avatar avatar-md brround mr-3 d-block cover-image" data-image-src="assets/images/users/female/8.jpg">-->
<!--                  <span class="avatar-status bg-yellow"></span>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <h6 class="mb-1">Nicola Vance</h6>-->
<!--                  <p class="mb-0 fs-13">New Schedule Realease</p>-->
<!--                  <div class="small text-muted">45 mintues ago</div>-->
<!--                </div>-->
<!--              </a>-->
<!--              <div class="border-top">-->
<!--                <a href="#" class="dropdown-item text-center">See all Messages</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>&lt;!&ndash; MESSAGE-BOX &ndash;&gt;-->
<!--          <div class="dropdown header-user">-->
<!--            <a href="#" class="nav-link icon" data-toggle="dropdown">-->
<!--              <i class="fe fe-activity" ></i>-->
<!--            </a>-->
<!--            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">-->
<!--              <a class="dropdown-item" href="profile.html">-->
<!--                <i class="mdi mdi-account-outline mr-2"></i> <span>My profile</span>-->
<!--              </a>-->
<!--              <a class="dropdown-item" href="#">-->
<!--                <i class="mdi mdi-settings mr-2"></i> <span>Settings</span>-->
<!--              </a>-->
<!--              <a class="dropdown-item" href="#">-->
<!--                <i class="fe fe-calendar mr-2"></i> <span>Activity</span>-->
<!--              </a>-->
<!--              <a class="dropdown-item" href="#">-->
<!--                <i class="mdi mdi-compass-outline mr-2"></i> <span>Support</span>-->
<!--              </a>-->
<!--              <a class="dropdown-item" href="login.html">-->
<!--                <i class="mdi  mdi-logout-variant mr-2"></i> <span>Logout</span>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>&lt;!&ndash; SIDE-MENU &ndash;&gt;-->
<!--          <div class="dropdown  header-fullscreen">-->
<!--            <a class="nav-link icon sidebar-right-mobile" data-toggle="sidebar-right" data-target=".sidebar-right">-->
<!--              <i class="fe fe-align-right" ></i>-->
<!--            </a>-->
<!--          </div>&lt;!&ndash; Side menu &ndash;&gt;-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceTypeSetter from "@/views/components/DeviceTypeSetter"
import SmsIcon from "@/views/components/Icon";
import auth from "@/plugins/auth";

export default {
  name: 'sms-header',
  components: {SmsIcon, DeviceTypeSetter},
  methods: {
    createTask() {
      this.$store.dispatch('tasks/create', {name: 'Untitled'}).then(task => {
        this.$router.push({name: 'task-view', params: {id: task.id}})
      })
    },
    logOut() {
      auth.logOut()

      window.location.href = '/'
    }
  },
}
</script>

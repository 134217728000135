<template>
  <div :class="'alert-' + type" class="alert">{{ message }}</div>
</template>

<script>
export default {
  name: 'sms-alert',
  props: {
    message: {
      type: String
    },
    type: {
      type: String,
      default: 'primary',
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  destroyed() {
  },
  methods: {},
}
</script>

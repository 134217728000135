<template>
  <sms-card header-title="Weight log">
    <input type="text" v-model="weight" :disabled="day.weight || saved" />

    <a href="#" @click.prevent="save" v-if="!day.weight && !saved">save</a>
  </sms-card>
</template>

<script>
import axios from "@/plugins/axios"
import SmsCard from "@/views/components/Card";

export default {
  props: ['day'],
  components: {SmsCard},
  data() {
    return {
      weight: this.day.weight,
      saved: false,
    }
  },
  mounted() {
  },
  methods: {
    save() {
      axios.post('/health/log/weight', {date: this.day.date, log: this.weight})
        .then(response => {
          this.saved = true
        })
    },
  },
}
</script>

import jQuery from 'jquery'

(function ($) {
  "use strict";

  var slideMenu = $('.side-menu');

  // Toggle Sidebar
  $(document).on('click', '[data-toggle="sidebar"]', function (event) {
    event.preventDefault();
    $('.app').toggleClass('sidenav-toggled');
    $(".app-header").toggleClass('sidebar-opened')
  });

  $(window).on('load resize', function () {
    if ($(window).width() < 767) {
      $('.app-sidebar').hover(function (event) {
        event.preventDefault();
        $('.app').addClass('sidenav-toggled');
      });
    }
    if ($(window).width() > 767) {
      $('.app-sidebar').hover(function (event) {
        event.preventDefault();
        $('.app').removeClass('sidenav-toggled');
      });
    }
  });


  // Activate sidebar slide toggle
  $("[data-toggle='slide']").on('click', function (event) {
    event.preventDefault();
    if (!$(this).parent().hasClass('is-expanded')) {
      slideMenu.find("[data-toggle='slide']").parent().removeClass('is-expanded');
    }
    $(this).parent().toggleClass('is-expanded');
  });

  // Set initial active toggle
  $("[data-toggle='slide.'].is-expanded").parent().toggleClass('is-expanded');

  //Activate bootstrip tooltips
  // $("[data-toggle='tooltip']").tooltip();

  // ______________ SideBar
  $(".app-sidebar a").each(function () {
    var pageUrl = window.location.href.split(/[?#]/)[0];
    if (this.href == pageUrl) {
      $(this).addClass("active");
      $(this).parent().addClass("active"); // add active to li of the current link
      $(this).parent().parent().prev().addClass("active"); // add active class to an anchor
      $(this).parent().parent().prev().click(); // click the item to make it drop
    }
  });

  // const ps1 = new PerfectScrollbar('.app-sidebar', {
  //   useBothWheelAxes: false,
  //   suppressScrollX: false,
  // });

})(jQuery);

import auth from "./auth";

const axios = require('axios')

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_ENDPOINT;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

if (auth.token()) {
  axios.defaults.headers.common['Auth-Token'] = auth.token()
}

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {

  if (error.response.data && error.response.data.message) {
    alert(error.response.data.message)
  } else {
    console.log('error happend', error.response)

    if (error.response.status === 422) {
      alert('Error happend. Status ' + JSON.stringify(error.response.data))
    } else {
      alert('Error happend. Status ' + error.response.status + '. ' + error.response.statusText)
    }
  }

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default axios

<template>
  <sms-content title="My editor">
    <sms-grid-row>
      <sms-card>
        <my-editor :blocks="blocks" @onChange="onChange" />
      </sms-card>
      <sms-card>
        <p v-for="block in blocks" :key="block.id">
          {{ block }}
        </p>
      </sms-card>
    </sms-grid-row>
  </sms-content>
</template>

<script>
import MyEditor from "@/my-editor/MyEditor"
import SmsContent from "@/views/partials/Content";
import SmsGridRow from "@/views/components/GridRow";
import SmsCard from "@/views/components/Card";

export default {
  components: {SmsCard, SmsGridRow, SmsContent, MyEditor},
  data() {
    return {
      content: '',
      blocks: [] ,
    }
  },
  computed: {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    onChange(blocks) {
      this.blocks = blocks
    },
  },
}
</script>

<template>
  <sms-content title="Tasks projects">
    <sms-grid-row>
      <sms-card>        <ul>
          <li v-for="project in projects" :key="project.id">
            <router-link :to="{name: 'task-project', params: {id: project.id}}">{{ project.name }}</router-link>

            [{{ project.status }}]
          </li>
        </ul>

        <hr />

        <input v-model="newProjectName" @keypress.enter="createProject" />
      </sms-card>
    </sms-grid-row>
  </sms-content>
</template>

<script>
import axios from "@/plugins/axios"
import SmsContent from "@/views/partials/Content";
import SmsGridRow from "@/views/components/GridRow";
import SmsCard from "@/views/components/Card";

export default {
  components: {SmsCard, SmsGridRow, SmsContent},
  data() {
    return {
      projects: [],
      newProjectName: '',
    }
  },
  computed: {},
  mounted() {
    this.loadProjects()
  },
  methods: {
    loadProjects() {
      axios.get('/task/project/list')
        .then(response => {
          this.projects = response.data.projects
        })
    },
    createProject() {
      axios.post('/task/project', {name: this.newProjectName})
              .then(response => {
                this.projects.push(response.data.project)
                this.newProjectName = ''
              })
    },
  },
}
</script>

<template>
  <sms-content v-if="song" :breadcrumbs="breadcrumbs">
    <sms-grid-row>
      <sms-grid-col :size="[3]">
        <song-info />
        <tracks-list />
      </sms-grid-col>

      <sms-grid-col :size="[9]">
        <sms-card>
          <sms-grid-row>
            <sms-grid-col :size="[3]">
              <player-controls sprite-name="full" />
            </sms-grid-col>
            <sms-grid-col :size="[5]">
              <speed-control segment-id="full" :speed="song.speed ? song.speed : 1"></speed-control>
            </sms-grid-col>
            <sms-grid-col :size="[4]">
              <delay-slider></delay-slider>
            </sms-grid-col>
          </sms-grid-row>

          <hr />

          <sms-grid-row>
            <sms-grid-col>
              <track-player />
            </sms-grid-col>
          </sms-grid-row>
        </sms-card>

        <sms-card>
          <track-segments />
        </sms-card>
      </sms-grid-col>
    </sms-grid-row>

    <template #tools>
      <a v-b-modal.add-track href="#" class="btn btn-primary btn-icon btn-sm text-white">
        <span>
            <i class="fe fe-plus"></i>
        </span> Add track
      </a>
    </template>
  </sms-content>
</template>

<script>
import 'vue-slider-component/theme/default.css'
import SongInfo from "./SongInfo";
import TracksList from "./tracks/TracksList";
import TrackPlayer from "./tracks/TrackPlayer";
import TrackSegments from "./tracks/TrackSegments";
import DelaySlider from "./DelaySlider";
import SmsContent from "@/views/partials/Content";
import SmsGridRow from "@/views/components/GridRow";
import SmsCard from "@/views/components/Card";
import SmsGridCol from "@/views/components/GridColumn";
import axios from "@/plugins/axios";
import PlayerControls from "@/views/pages/guitar/song/tracks/PlayerControls";
import SpeedControl from "@/views/pages/guitar/song/tracks/SpeedControl";

export default {
  components: {
    SpeedControl,
    PlayerControls,
    SmsGridCol,
    SmsCard,
    SmsGridRow, SmsContent, DelaySlider, TrackSegments, TrackPlayer, TracksList, SongInfo},
  computed: {
    songName() {
      return this.song.band_name + ' - ' + this.song.name
    },
    song() {
      return this.$store.state.guitar.song
    },
    breadcrumbs() {
      return [
        {
          label: 'Guitar songs',
          route: {name: 'guitar'},
        },
        {
          label: 'Song',
        }
      ]
    },
  },
  mounted() {
    this.loadSong()
  },
  methods: {
    loadSong() {
      this.$store.dispatch('guitar/loadSong', {song_id: this.$route.params.id})
        .then(song => {
          axios.post('/guitar/song/' + song.id + '/update-last-viewed')
        })
    },
  },
}
</script>

<template>
  <div>
    Test

    <hr />

    File: {{ file }}
    <br />
    File path: {{ filePath }}
    <br />
    Duration: {{ duration }}

    <br />

    <img :src="src" />

    <input type="file" ref="file" @change="onFileSelected" />

    <br />
    <a href="#" @click.prevent="play">Play</a>

    <input type="submit" value="Send" @click.prevent="send" />
  </div>
</template>

<script>
import {Howl} from "howler";
import axios from "@/plugins/axios";

export default {
  components: {},
  data() {
    return {
      file: '',
      filePath: '',
      duration: '',
      src: '',
    }
  },
  computed: {},
  mounted() {
  },
  destroyed() {
  },
  methods: {
    onFileSelected() {
      this.file = this.$refs.file.files[0]

      this.filePath = URL.createObjectURL(this.file)

      console.log(this.file)
    },
    play() {
      let sound = new Howl({
        src: [this.filePath],
        html5: true,
        loop: true,
        format: 'mp3',
      })

      sound.on('load', x => {
        this.duration = sound.duration().toFixed(2)
        sound.play()

        console.log('test')
      })
    },
    send() {
      console.log(this.file)

      var formData = new FormData();
      formData.append("mp3", this.file);

      axios.post('/test', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
    },
  },
}
</script>

import Vue from 'vue'
import VueRouter from "vue-router"
import auth from '../plugins/auth'
import AuthPage from "../pages/AuthPage";
import jQuery from "jquery"

Vue.use(VueRouter)

import tasksRoutes from '@/router/tasks-routes'
import overviewRoutes from '@/router/overview-routes'
import crudRoutes from '@/router/crud-routes'

import DashboardPage from '@/views/pages/DashboardPage'
import ContentEditableDev from "@/views/pages/ContentEditableDevPage"
import TrainingPage from "@/views/pages/TrainingPage"
import ContactsListPage from "@/views/pages/ContactsListPage"
import ContactViewPage from "@/views/pages/ContactViewPage"
import CarPage from "@/views/pages/CarPage";
import PcPage from "@/views/pages/PcPage";
import HousePage from "@/views/pages/HousePage";
import PageViewPage from '@/views/pages/PageView'
import GuitarPage from "@/views/pages/guitar/Index"
import GuitarSong from "@/views/pages/guitar/song/Index"

import KnowledgeBasePage from "@/pages/KnowledgeBasePage"
import LogsSleep from "@/pages/LogsSleep"
import TestPage from "@/pages/TestPage"
import LogsWeight from "@/pages/LogsWeight";

import BooksPage from "../pages/BooksPage";
import KateMovies from "../pages/KateMovies";
import OrgListPage from "../pages/OrgListPage";
import OrgViewPage from "../pages/OrgViewPage";
import IssuesPage from "@/pages/IssuesPage";
import GamesPage from "@/pages/GamesPage";
import SongsPage from "@/pages/SongsPage";
import HealthPage from "@/pages/HealthPage";

const routes = [
  ...[
    {
      path: '/auth',
      component: AuthPage,
      name: 'auth',
    },
    {
      path: '/dashboard',
      component: DashboardPage,
      name: 'dashboard',
    },
    {
      path: '/content-editable-dev',
      component: ContentEditableDev,
      name: 'content-editable-dev',
    },
    {
      path: '/page/:id',
      component: PageViewPage,
      name: 'page-view',
    },
    {
      path: '/issues',
      component: IssuesPage,
      name: 'issues-list',
    },
    {
      path: '/kb',
      component: KnowledgeBasePage,
      name: 'kb',
    },
    {
      path: '/guitar',
      component: GuitarPage,
      name: 'guitar',
    },
    {
      path: '/guitar/song/:id',
      component: GuitarSong,
      name: 'guitar-song',
    },
    {
      path: '/logs/sleep',
      component: LogsSleep,
      name: 'logs-sleep',
    },
    {
      path: '/logs/weight',
      component: LogsWeight,
      name: 'logs-weight',
    },
    {
      path: '/test',
      component: TestPage,
      name: 'test',
    },
    {
      path: '/pc',
      component: PcPage,
      name: 'pc',
    },
    {
      path: '/house',
      component: HousePage,
      name: 'house',
    },
    {
      path: '/contacts',
      component: ContactsListPage,
      name: 'contacts-list',
    },
    {
      path: '/contact/:id',
      component: ContactViewPage,
      name: 'contact-view',
    },
    {
      path: '/orgs',
      component: OrgListPage,
      name: 'orgs-list',
    },
    {
      path: '/org/:id',
      component: OrgViewPage,
      name: 'org-view',
    },
    {
      path: '/car',
      component: CarPage,
      name: 'car',
    },
    {
      path: '/books',
      component: BooksPage,
      name: 'books',
    },
    {
      path: '/games',
      component: GamesPage,
      name: 'games',
    },
    {
      path: '/songs',
      component: SongsPage,
      name: 'songs',
    },
    {
      path: '/health',
      component: HealthPage,
      name: 'health',
    },
    {
      path: '/training',
      component: TrainingPage,
      name: 'training',
    },
    {
      path: '/kate-movies',
      component: KateMovies,
      name: 'kate-movies',
    },
  ],
  ...tasksRoutes,
  ...overviewRoutes,
  ...crudRoutes,
]

let router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'auth') {
    if (!auth.loggedIn()) {
      next({name: 'auth'})
    }
  } else {
    if (auth.loggedIn()) {
      next({path: '/'})
    }
  }

  if (to.path === '/') {
    next({name: 'days-overview', params: {day: 'today'}})
  }

  next()
})

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('is_mobile') === '1') {
    jQuery(".page-wrapper").removeClass("toggled")
  }

  next()
})

export default router

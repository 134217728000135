<template>
  <b-modal id="add-track" title="Adding track">
    <form method="post" enctype="multipart/form-data" @submit.prevent="addTrack($event.target)">
      <div class="form-group">
        <input type="text" v-model="newTrack.name" placeholder="track name" />
      </div>

      <div class="form-group">
        <input type="file" ref="mp3_file" @change="onFileSelected">
      </div>

      <div class="form-group">
        Duration: {{ duration }}
      </div>

      <div class="form-group">
        <label>
          <input type="checkbox" v-model="newTrack.isDefault" /> Is default
        </label>
      </div>

      <div class="form-group">
        <input type="submit" value="add" name="submit" />
      </div>

    </form>
  </b-modal>
</template>

<script>
import {Howl} from "howler";

export default {
  data() {
    return {
      newTrack: this.newTrackResetted(),
      duration: '-',
      mp3File: null
    }
  },
  methods: {
    onFileSelected() {
      this.mp3File = this.$refs.mp3_file.files[0]
      const mp3FilePath = URL.createObjectURL(this.mp3File)

      let sound = new Howl({
        src: [mp3FilePath],
        html5: true,
        loop: true,
        format: 'mp3',
      })

      sound.on('load', x => {
        this.duration = sound.duration().toFixed(2)
      })
    },
    addTrack() {
      let formData = new FormData();
      formData.append("mp3_file", this.mp3File)
      formData.append('name', this.newTrack.name)
      formData.append('is_default', this.newTrack.isDefault ? 1 : 0)
      formData.append('duration', this.duration)

      this.$store.dispatch('guitar/addTrackToSong', formData).then(data => {
        this.newTrack = this.newTrackResetted()
        this.duration = '-'
        this.mp3File = null
      })
    },
    newTrackResetted() {
      return {
        name: '',
        isDefault: false,
      }
    }
  },
}
</script>

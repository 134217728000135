import Vuex from 'vuex'
import Vue from "vue";
import pages from './pages'
import tasks from './tasks'
import kb from './kb'
import guitar from "./guitar"
import crud from "./crud"
import dayOverview from "./day-overview"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    pages,
    tasks,
    kb,
    guitar,
    crud,
    dayOverview,
  },
})

<template>
  <div>
    <router-link v-if="pageId" :to="{name: 'page-view', params: {id: pageId}}">{{ name }}</router-link>
    <span v-if="!pageId">creating page...</span>
  </div>
</template>

<script>
export default {
  props: ['data', 'editor', 'id', 'index'],
  data() {
    return {
      name: this.data.name,
      pageId: null,
    }
  },
  mounted() {
    if (this.data.page_id) {
      this.pageId = this.data.page_id
    } else {
      this.createPage()
    }
  },
  methods: {
    onDelete() {
      this.$store.dispatch('pages/deletePage', {
        page: {
          id: this.pageId
        }
      })
    },
    focus() {},
    getBlockType() {
      return 'subpage'
    },
    getBlockData() {
      if (!this.pageId) {
        return null
      }

      return {
        page_id: this.pageId,
        name: this.name,
      }
    },
    createPage() {
      let data = {
        title: this.name,
      }

      if (this.$route.name === 'page-view') {
        data['parent_id'] = this.$route.params.id
      }

      this.$store.dispatch('pages/createPage', data).then(page => {
        this.pageId = page.id

        this.editor.emitOnChange()
      })
    },
  }
}
</script>

<style scoped>
.divider {
  margin-top: 10px;
  margin-bottom: 15px;
  padding-top: 1px;
}
</style>

<template>
    <div class="notes-container text-left w-100" style="width: 100%;">
        <div v-show="notFound">Not found</div>
        <div v-show="isSearchGoing">Loading...</div>

        <div v-for="note in notesTree"
             :key="note.id"
             class="border-dark border-left position-relative"
             style="padding-left: 10px;"
        >
            <note-tree
                :indent="false"
                type="parent"
                :max-level="2"
                :note="note"
                class="position-absolute"
                style="left: -100px;"
                hidden
            />

            <note highlight :note="note"></note>

            <markdown-textarea :value="note.text ? note.text : ''" @onUpdate="updateText"></markdown-textarea>

            <hr />

            <note-tree type="child" :max-level="parseInt(maxLevel)" :note="note" />
        </div>
    </div>
</template>

<script>
import Note from './Note'
import NoteTree from './NoteTree'
import MarkdownTextarea from "../../components/MarkdownTextarea"

export default {
  components: {
    MarkdownTextarea,
    Note,
    NoteTree,
  },
  data() {
    return {
      editMode: false,
    }
  },
  computed: {
    maxLevel: {
        get() {
            return this.$store.state.kb.notesChildsMaxLevel
        },
        set(val) {
            this.$store.commit('kb/setNotesChildsMaxLevel', val)
        }
    },
    notFound() {
        return this.$store.state.kb.notFound
    },
    notesTree() {
        return this.$store.state.kb.notesTree
    },
    isSearchGoing() {
        return this.$store.state.kb.isSearchGoing
    },
    searchQuery() {
        return this.$store.state.kb.searchQuery
    }
  },
  methods: {
    updateText(value) {
      this.$store.dispatch('kb/updateNoteText', {id: this.notesTree[0].id, text: value})

      this.notesTree[0].text = value
    }
  },
}
</script>

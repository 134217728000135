import axios from "@/plugins/axios"

export default {
  namespaced: true,
  state: {
    editingNote: {},
    notesTree: [],
    searchQuery: '',
    stickyNotes: [],
    allNotesCount: 0,
    notesChildsMaxLevel: 2,
    isSearchGoing: false,
    notFound: false,
  },
  mutations: {
    setNotFound(state, value) {
      state.notFound = value
    },
    setIsSearchGoing(state, value) {
      state.isSearchGoing = value
    },
    setNotesChildsMaxLevel(state, level) {
      if (level <= 0 || level > 10) {
        return
      }

      state.notesChildsMaxLevel = level
    },
    updateAllNotesCount(state, count) {
      state.allNotesCount = count
    },
    addStickyNote(state, note) {
      for (let index in state.stickyNotes) {
        if (state.stickyNotes[index].id === note.id) {
          return
        }
      }

      state.stickyNotes.push(note)
    },
    removeStickyNote(state, note) {
      let index = state.stickyNotes.indexOf(note)
      state.stickyNotes.splice(index, 1)
    },
    clearStickyNotes(state) {
      state.stickyNotes = []
    },
    updateEditingNote(state, note) {
      state.editingNote = note
    },
    clearEditingNote(state) {
      state.editingNote = {
        id: null,
        title: '',
        type: null,
        relations: [],
      }
    },
    setSearchQuery(state, searchQuery) {
      state.searchQuery = searchQuery
    },
    updateNotesTree(state, notesTree) {
      state.notesTree = notesTree
    },
    addNewNoteRelation(state, note) {
      state.editingNote.relations.push(note)
    },
    removeNewNoteRelation(state, note) {
      const index = state.editingNote.relations.indexOf(note)
      state.editingNote.relations.splice(index, 1)
    },
    clearNewNoteRelations(state) {
      state.editingNote.relations = []
    },
  },
  actions: {
    updateAllNotesCount({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/note/count/all').then(response => {
          commit('updateAllNotesCount', response.data)

          resolve(response.data)
        })
      })
    },
    deleteNote({commit, dispatch, state}, note) {
      return new Promise((resolve, reject) => {
        axios.delete('/note/delete/' + note.id).then(response => {
          dispatch('searchNote', state.searchQuery)
          dispatch('updateAllNotesCount')
          resolve()
        }).catch(error => {
          alert('error deleting')

          reject()
        })
      })
    },
    searchNote({commit}, searchQuery) {
      commit('setIsSearchGoing', true)
      commit('setNotFound', false)
      commit('updateNotesTree', [])

      return new Promise((resolve, reject) => {
        axios.post('/note/search', {query: searchQuery}).then(response => {
          console.log('search ok', response)

          commit('setIsSearchGoing', false)
          commit('setSearchQuery', searchQuery)
          commit('updateNotesTree', response.data.notes_tree)
          commit('setNotFound', response.data.notes_tree.length === 0)

          resolve(response.data)
        }).catch(error => {
          console.log('search error', error)

          alert('error')

          reject(error)
        })
      })
    },
    saveNote({dispatch}, note) {
      if (note.id) {
        return dispatch('updateNote', note)
      } else {
        return dispatch('createNote', note)
      }
    },
    createNote({dispatch}, note) {
      console.log('before creating');

      return new Promise((resolve, reject) => {
        axios.post('/note/create', note).then(response => {
          console.log('creating ok')

          dispatch('updateAllNotesCount')

          resolve(response.data)
        }).catch(error => {
          console.log('error create note')

          reject(error)
        })
      })
    },
    updateNote({dispatch}, note) {
      return new Promise((resolve, reject) => {
        axios.post('/note/update/' + note.id, note).then(response => {
          console.log('updating ok')
          dispatch('updateAllNotesCount')

          resolve(response.data)
        }).catch(error => {
          console.log('error updating note')

          reject(error)
        })
      })
    },
    updateNoteText({dispatch}, note) {
      return new Promise((resolve, reject) => {
        axios.post('/note/update/' + note.id + '/text', {text: note.text}).then(response => {
          console.log('updating text ok')

          resolve(response.data)
        }).catch(error => {
          console.log('error updating note text')

          reject(error)
        })
      })
    },
    loadNote({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.get('/note/' + id).then(response => {
          console.log('loading note ok')

          resolve(response.data)
        }).catch(error => {
          console.log('error loading note')

          reject(error)
        })
      })
    },
  },
}

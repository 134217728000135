<template>
  <div class="player-controls">
    <span @click.prevent="backward" style="margin-right: 10px;">
      <sms-icon name="fe fe-rewind" :color="isPlaying ? 'success' : null"></sms-icon>
    </span>
    <span @click.prevent="play" style="margin-right: 10px;" v-show="!isPlaying">
      <sms-icon name="fe fe-play" style="color: #28d028;"></sms-icon>
    </span>
    <span @click.prevent="pause" style="margin-right: 10px;" v-show="isPlaying">
      <sms-icon name="fe fe-pause" color="red"></sms-icon>
    </span>
    <span @click.prevent="stop" style="margin-right: 10px;">
      <sms-icon name="fe fe-square" :color="isPlaying ? 'red' : null"></sms-icon>
    </span>
    <span @click.prevent="replay" style="margin-right: 10px;">
      <sms-icon name="fe fe-rotate-cw" :color="isPlaying ? 'success' : null"></sms-icon>
    </span>
    <span @click.prevent="forward" style="margin-right: 10px;">
      <sms-icon name="fe fe-fast-forward" :color="isPlaying ? 'success' : null"></sms-icon>
    </span>
    <br />
    <span style="margin-right: 10px;" v-if="showTime">{{ songTime }}</span>
  </div>
</template>

<script>
import SmsIcon from "@/views/components/Icon";
export default {
  props: {
    spriteName: {
      type: String,
    },
    showTime: {
      type: Boolean,
      default: true,
    }
  },
  components: {SmsIcon},
  data() {
    return {}
  },
  computed: {
    songCurrentPosition() {
      return this.$store.state.guitar.soundData.currentPosition
    },
    songTime() {
      if (isNaN(this.songCurrentPosition)) {
        return '--:--'
      }

      let seconds = (this.songCurrentPosition % 60).toFixed(2)
      seconds = seconds === 60 ? 0 : seconds
      seconds = seconds < 10 ? '0' + seconds : seconds

      return Math.floor(this.songCurrentPosition / 60)
              + ':'
              + seconds
              + ' → ' + (this.songCurrentPosition).toFixed(2)
    },
    isPlaying() {
      return this.$store.state.guitar.currentPlayingSprite === this.spriteName
        && this.$store.state.guitar.sound.playing(this.$store.state.guitar.soundId)
    },
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    play() {
      this.$store.dispatch('guitar/playSound', this.spriteName)
    },
    replay() {
      this.stop()
      this.play()
    },
    stop() {
      this.$store.dispatch('guitar/stopSound')
    },
    pause() {
      this.$store.dispatch('guitar/pauseSound')
    },
    backward() {
      let position = this.$store.state.guitar.soundData.currentPosition
      position = position - 5

      if (position < 0) {
        position = 0
      }

      this.$store.dispatch('guitar/seekSound', position)
    },
    forward() {
      let position = this.$store.state.guitar.soundData.currentPosition
      position = position + 5

      this.$store.dispatch('guitar/seekSound', position)
    },
  },
}
</script>

<style scoped>
.player-controls span {
  cursor: pointer;
}
.player-controls i {
  font-size: 30px;
}
</style>

<template>
  <div>
    <h1>Games</h1>

    <sms-page id="games"></sms-page>
  </div>
</template>

<script>
import SmsPage from "@/components/SmsPage";
export default {
  components: {SmsPage},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  destroyed() {},
  methods: {},
}
</script>

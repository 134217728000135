<template>
  <a href="#" data-toggle="search" class="nav-link icon">
    <i :class="[deviceType === 'mobile' ? 'fa-mobile' : 'fa-desktop']"
       @click.prevent="toggleDeviceType"
       class="fa"
       data-toggle="tooltip"
       title="Переключение типа устройства"
    ></i>
  </a>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      deviceType: null,
    }
  },
  mounted() {
    this.deviceType = localStorage.getItem('is_mobile') === '1' ? 'mobile' : 'desktop'
  },
  methods: {
    toggleDeviceType() {
      this.deviceType = this.deviceType === 'mobile' ? 'desktop' : 'mobile'
      localStorage.setItem('is_mobile', this.deviceType === 'mobile' ? '1' : '0')
    },
  },
}
</script>

<template>
  <sms-content title="Task" v-if="!isEmptyTask">
    <sms-grid-row>
      <sms-card :size="[6]">
        <h1>
          <content-editable-input :value="task.name" @onBlur="saveTaskName" @onEnter="saveTaskName" />
        </h1>

        <div v-if="task.is_deleted" class="alert alert-danger">
          deleted
        </div>

        <table class="table table-sm">
              <tr>
                <td>Inbox</td>
                <td>{{ task.inbox ? 'YES' : 'NO' }}
                  <a @click="updateProperty('inbox')" class="btn btn-sm btn-success" v-if="!task.inbox">make inbox</a></td>
              </tr>
              <tr>
                <td>Done</td>
                <td>{{ task.is_done ? 'YES' : 'NO' }}</td>
              </tr>

              <tr>
                <td>Importance</td>
                <td>
                  <input type="checkbox"
                         :checked="task.is_important"
                         @change="updateProperty('importance', {importance: $event.target.checked})"
                  />
                </td>
              </tr>

            <tr>
              <td>Chore</td>
              <td>
                <input type="checkbox"
                       :checked="task.is_chore"
                       @change="updateProperty('chore', {chore: $event.target.checked})"
                />
              </td>
            </tr>

            <tr>
              <td>Outdoor</td>
              <td>
                <input type="checkbox"
                       :checked="task.is_outdoor"
                       @change="updateProperty('outdoor', {outdoor: $event.target.checked})"
                />
              </td>
            </tr>

              <tr>
                <td>Project</td>
                <td>
                  <select
                          @change="updateProperty('project', {project_id: $event.target.value})"
                  >
                    <option value="">---</option>
                    <option v-for="project in projects"
                            :key="project.id"
                            :value="project.id"
                            :selected="task.project_id === project.id"
                    >{{ project.name }}</option>
                  </select>

                  <span v-if="selectedProject">
              <router-link :to="{name: 'task-project', params: {id: selectedProject.id}}">{{ selectedProject.name }}</router-link>
            </span>
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  <!--          <editable-text :value="task.due_date" @onBlur="saveDueDate"></editable-text>-->

                  <date-picker :value="task.due_date ? new Date(task.due_date) : null"
                               @input="onDueDateChanged"
                               :min-date="new Date()"
                  />

                </td>
              </tr>
              <tr>
                <td>Time of day</td>
                <td>
                  <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                    <button :class="{'btn-success': task.time_of_day === 'morning'}" @click="setTimeOfDay('morning')" type="button" class="btn btn-default">Утро</button>
                    <button :class="{'btn-success': task.time_of_day === 'afternoon'}" @click="setTimeOfDay('afternoon')" type="button" class="btn btn-default">День</button>
                    <button :class="{'btn-success': task.time_of_day === 'evening'}" @click="setTimeOfDay('evening')" type="button" class="btn btn-default">Вечер</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Recurring</td>
                <td>
                  <content-editable-input :value="task.recurring" @onBlur="saveRecurring" @onEnter="saveRecurring" />
                </td>
              </tr>

              <tr>
                <td>Notify at</td>
                <td>
                  <content-editable-input :value="task.notify_at_time" @onBlur="saveNotifyAtTime" @onEnter="saveNotifyAtTime" />
                </td>
              </tr>

              <tr>
                <td>Created at</td>
                <td>{{ task.created_at }}</td>
              </tr>
              <tr>
                <td>Updated at</td>
                <td>{{ task.updated_at }}</td>
              </tr>
            </table>

        <hr />

        <a v-if="!task.is_deleted" @click.prevent="deleteTask" href="#" class="btn btn-danger btn-sm">delete</a>
        <button v-if="task.is_deleted" @click="restoreTask" class="btn btn-success btn-sm">Restore</button>
      </sms-card>
      <sms-card :size="[6]">
        {{ task.text }}

        <my-editor :blocks="task.text_blocks" @onChange="saveTextBlocks" />
      </sms-card>
    </sms-grid-row>
  </sms-content>
</template>

<script>
import DatePicker from "@/components/DatePicker"
import _isEmpty from 'lodash/isEmpty'
import ContentEditableInput from "@/components/ContentEditableInput"
import axios from "@/plugins/axios"
import MyEditor from "@/my-editor/MyEditor"
import SmsContent from "@/views/partials/Content"
import SmsGridRow from "@/views/components/GridRow"
import SmsCard from "@/views/components/Card"

export default {
  components: {SmsCard, SmsGridRow, SmsContent, MyEditor, ContentEditableInput, DatePicker},
  data() {
    return {
      dueDate: null,
      projects: [],
    }
  },
  computed: {
    task() {
      return this.$store.state.tasks.viewingTask
    },
    isEmptyTask() {
      return _isEmpty(this.task)
    },
    selectedProject() {
      return this.task.project_id ? this.projects.filter(x => x.id === this.task.project_id)[0] : null
    },
  },
  mounted() {
    this.$store.dispatch('tasks/loadOne', {id: this.$route.params.id})
    this.loadProjects()
  },
  watch: {
    $route(val) {
      this.$router.go()
    },
  },
  beforeDestroy() {
    this.$store.commit('tasks/setViewingTask', {})
  },
  methods: {
    deleteTask() {
      this.$store.dispatch('tasks/delete', {id: this.task.id})
        .then(task => {
          this.$store.commit('tasks/setViewingTask', task)
        })
    },
    saveTaskName(name) {
      console.log('name', name, this.task.name)

      if (this.task.name === name) {
        return
      }

      this.$store.dispatch('tasks/rename', {id: this.task.id, name: name})
        .then(task => {
          this.$store.commit('tasks/setViewingTask', task)
        })
    },
    saveTextBlocks(blocks) {
      this.$store.dispatch('tasks/setProperty', {
        id: this.task.id,
        property: 'text_blocks',
        payload: {blocks}
      })
    },
    onDueDateChanged(date) {
      let dueDate = ''

      if (date) {
         dueDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      }

      this.$store.dispatch('tasks/setDueDate', {id: this.task.id, dueDate: dueDate})
              .then(task => {
                this.$store.commit('tasks/setViewingTask', task)
              })
    },
    saveRecurring(recurring) {
      if (!recurring) {
        recurring = null
      }

      if (this.task.recurring === recurring) {
        return
      }

      this.$store.dispatch('tasks/setRecurring', {id: this.task.id, recurring: JSON.parse(recurring)})
        .then(task => {
          this.$store.commit('tasks/setViewingTask', task)
        })
    },
    saveNotifyAtTime(time) {
      this.updateProperty('notify-at-time', {time: time})
    },
    setTimeOfDay(timeOfDay) {
      this.$store.dispatch('tasks/setProperty', {
        id: this.task.id,
        property: 'time-of-day',
        payload: {time_of_day: timeOfDay}
      }).then(() => {
        this.$store.dispatch('tasks/loadOne', {id: this.$route.params.id})
      })
    },
    updateProperty(property, value) {
      console.log(property, value)

      this.$store.dispatch('tasks/setProperty', {
        id: this.task.id,
        property: property,
        payload: value
      })
    },
    restoreTask() {
      this.$store.dispatch('tasks/restore', {
        id: this.task.id,
      }).then(task => {
        this.$store.commit('tasks/setViewingTask', task)
      })
    },
    loadProjects() {
      axios.get('/task/project/list').then(response => {
        this.projects = response.data.projects
      })
    },
  }
}
</script>

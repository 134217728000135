<template>
  <div class="day-overview-tasks" :style="{'border-left-color': this.borderColor}">
    <task-items :day-tasks="notChoreTasks"></task-items>
    <task-items :day-tasks="choreTasks" v-if="choreTasks.length > 0"></task-items>
  </div>
</template>

<script>
import TaskItems from "@/views/pages/day-overview/task-items";

export default {
  components: {TaskItems},
  props: ['dayTasks', 'color'],
  data() {
    return {
      firstChoreTaskSet: false,
      sortedTasks: [],
    }
  },
  computed: {
    choreTasks() {
      return this.dayTasks.filter(x => x.task.is_chore)
    },
    notChoreTasks() {
      return this.dayTasks.filter(x => !x.task.is_chore)
    },
    borderColor() {
      return this.color ? this.color : 'red'
    }
  },
}
</script>

<style scoped>
.day-overview-tasks {
  border-left: 2px solid #f3d231;
}
</style>

<template>
  <div>
    <h1>Issues</h1>

    <sms-page id="issues-list"></sms-page>
  </div>
</template>

<script>
import SmsPage from "@/components/SmsPage"

export default {
  components: {SmsPage},
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  destroyed() {
  },
  methods: {},
}
</script>

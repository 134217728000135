<template>
    <div class="sticky-notes-container" style="text-align: left;">
        <h3>STICKY NOTES</h3>
        <p>
            <a class="btn btn-danger btn-sm d-block" @click.prevent="clearStickyNotes" href="">remove all</a>
        </p>

        <div v-for="note in stickyNotes" :key="note.id" class="sticky-notes-container">
            <note :note="note" :show-relations="false"></note>
        </div>
    </div>
</template>

<script>
import Note from './Note'

export default {
    components: {Note},
    computed: {
        stickyNotes() {
            return this.$store.state.kb.stickyNotes
        },
    },
    methods: {
        clearStickyNotes() {
            this.$store.commit('kb/clearStickyNotes')
        }
    }
}
</script>

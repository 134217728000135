<template>
  <sms-grid-col :size="size">
    <div class="card">

      <div v-if="headerTitle" class="card-header">
        <h3 class="card-title">{{ headerTitle }}</h3>

        <div class="card-options">
          <slot name="options">
            <a href="#" class="card-options-collapse" data-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a>
            <a href="#" class="card-options-remove" data-toggle="card-remove"><i class="fe fe-x"></i></a>
          </slot>
        </div>
      </div>

      <div :class="'p-' + bodyPadding" class="card-body">
        <slot></slot>
      </div>

    </div>
  </sms-grid-col>
</template>

<script>
import SmsGridCol from "@/views/components/GridColumn";
export default {
  name: 'sms-card',
  props: {
    size: {
      type: Array,
      default() {
        return [12, 12, 12, 12]
      },
    },
    headerTitle: {
      type: String,
      default: null,
    },
    bodyPadding: {
      type: Number,
      default: 3,
    },
  },
  components: {SmsGridCol},
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  destroyed() {
  },
  methods: {},
}
</script>

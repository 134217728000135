<template>
  <sms-content title="Годы">
    <sms-grid-row>
      <sms-card>
        <sms-page id="years-list-overview"></sms-page>
      </sms-card>
    </sms-grid-row>
  </sms-content>
</template>

<script>
import SmsPage from "@/components/SmsPage"
import SmsContent from "@/views/partials/Content";
import SmsCard from "@/views/components/Card";
import SmsGridRow from "@/views/components/GridRow";

export default {
  components: {SmsGridRow, SmsCard, SmsContent, SmsPage},
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  destroyed() {
  },
  methods: {},
}
</script>

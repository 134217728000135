<template>
  <vue-slider v-model="songCurrentPosition"
              @change="changeSongPosition"
              :interval="0.01"
              :min="sliderOptions.min"
              :max="sliderOptions.max"
              :tooltip="'none'"
              style="cursor: pointer;"
              :lazy="true"
              :marks="marks"
              :duration="0"
              :included="false"
  >
    <template v-slot:step="{ label, active }">
      <div :class="['custom-step', { active }]"></div>
    </template>
  </vue-slider>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    marks: {
      type: Object,
    }
  },
  components: {VueSlider},
  data() {
    return {
      sliderOptions: {
        min: this.min,
        max: this.max,
      }
    }
  },
  mounted() {
    console.log('timeline', this.sliderOptions.min, this.sliderOptions.max)

    if (isNaN(this.sliderOptions.min)) {
      this.sliderOptions.min = 0
    }

    if (isNaN(this.sliderOptions.max)) {
      this.sliderOptions.max = 0
    }

    this.sliderOptions.min.toFixed(2)
    this.sliderOptions.max.toFixed(2)

    if (this.sliderOptions.max < this.sliderOptions.min) {
      this.sliderOptions.max = this.sliderOptions.min
    }

    if (this.sliderOptions.min === this.sliderOptions.max) {
      this.sliderOptions.max += 0.01
    }

    console.log(
            'timeline changed',
            this.sliderOptions.min,
            this.sliderOptions.max,
            this.sliderOptions.max - this.sliderOptions.min
    )
  },
  computed: {
    songCurrentPosition: {
      get() {
        let position = this.$store.state.guitar.soundData.currentPosition

        if (position < this.min || position > this.max) {
          return this.min
        }

        return position
      },
      set(value) {

      },
    },
  },
  methods: {
    changeSongPosition(position) {
      this.$store.dispatch('guitar/seekSound', position)
    },
  },
}
</script>
